import * as tf from '@tensorflow/tfjs';

export default class NeuralInputProcessor {
  constructor() {
    this.model = null;
    this.init();
  }

  async init() {
    try {
      this.model = await tf.loadLayersModel('localstorage://input-prediction-model');
    } catch (error) {
      console.error('Failed to load neural network model:', error);
      console.log('Training new model...');
      await this.trainNeuralNetwork();
      this.model = await tf.loadLayersModel('localstorage://input-prediction-model');
    }
  }

  async trainNeuralNetwork() {
    const model = tf.sequential();
    model.add(tf.layers.dense({ units: 10, inputShape: [2], activation: 'relu' }));
    model.add(tf.layers.dense({ units: 2 }));

    model.compile({ optimizer: 'adam', loss: 'meanSquaredError' });

    const xs = tf.randomNormal([1000, 2]);
    const ys = xs.add(tf.randomNormal([1000, 2], 0, 0.1));

    await model.fit(xs, ys, {
      epochs: 100,
      batchSize: 32,
      callbacks: tf.callbacks.earlyStopping({ monitor: 'loss', patience: 5 })
    });

    await model.save('localstorage://input-prediction-model');
    console.log('Neural network trained and saved.');
  }

  predict(input) {
    if (!this.model) {
      return input;
    }

    const inputTensor = tf.tensor2d([input]);
    const prediction = this.model.predict(inputTensor);
    const result = prediction.dataSync();
    inputTensor.dispose();
    prediction.dispose();
    return { x: result[0], y: result[1] };
  }

  cleanup() {
    if (this.model) {
      this.model.dispose();
    }
  }
}