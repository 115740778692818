// /var/www/html/FrontEnd/src/services/CPUAccelerator.js
class CPUAccelerator {
    constructor() {
        console.log('Initializing CPU-based acceleration');
        this.workers = [];
        this.initializeWorkers();
    }

    initializeWorkers() {
        const numWorkers = navigator.hardwareConcurrency || 4;
        for (let i = 0; i < numWorkers; i++) {
            const worker = new Worker(new URL('./CPUWorker.js', import.meta.url));
            this.workers.push(worker);
        }
    }

    accelerate(data, operation) {
        return new Promise((resolve, reject) => {
            const worker = this.workers[Math.floor(Math.random() * this.workers.length)];
            worker.onmessage = (event) => resolve(event.data);
            worker.onerror = (error) => reject(error);
            worker.postMessage({ data, operation });
        });
    }

    processImage(imageData) {
        return new Promise((resolve, reject) => {
            const worker = this.workers[Math.floor(Math.random() * this.workers.length)];
            worker.onmessage = (event) => resolve(new ImageData(event.data.data, event.data.width, event.data.height));
            worker.onerror = (error) => reject(error);
            worker.postMessage({ operation: 'processImage', imageData });
        });
    }

    computeMatrix(matrix) {
        return new Promise((resolve, reject) => {
            const worker = this.workers[Math.floor(Math.random() * this.workers.length)];
            worker.onmessage = (event) => resolve(event.data);
            worker.onerror = (error) => reject(error);
            worker.postMessage({ operation: 'computeMatrix', matrix });
        });
    }

    terminate() {
        this.workers.forEach(worker => worker.terminate());
        this.workers = [];
    }
}

export default CPUAccelerator;