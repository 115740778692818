// useScreenSharing.js
import { useState, useCallback, useRef } from 'react';

const useScreenSharing = (sendSignal) => {
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const screenStreamRef = useRef(null);

  const startScreenSharing = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      screenStreamRef.current = stream;
      sendSignal({ type: 'screenShare', stream });
      setIsScreenSharing(true);

      stream.getVideoTracks()[0].onended = stopScreenSharing;
    } catch (err) {
      console.error('Error sharing screen:', err);
      // Handle error (e.g., show notification)
    }
  }, [sendSignal]);

  const stopScreenSharing = useCallback(() => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach(track => track.stop());
      sendSignal({ type: 'stopScreenShare' });
    }
    screenStreamRef.current = null;
    setIsScreenSharing(false);
  }, [sendSignal]);

  return {
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
  };
};

export default useScreenSharing;