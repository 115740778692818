import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Grid, Paper, Button, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, MenuItem, Select, FormControl, InputLabel, Card, CardContent, CardActions, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import api, { socketOn, connectSocket } from '../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  ticketList: {
    maxHeight: 400,
    overflow: 'auto',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ServiceWorkerDashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [earnings, setEarnings] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showAssignedOnly, setShowAssignedOnly] = useState(true);

  const fetchTickets = useCallback(async () => {
    try {
      const endpoint = showAssignedOnly ? '/api/service-workers/assigned-tickets' : '/api/tickets';
      const response = await api.get(`${endpoint}?sort=${sortField}&order=${sortOrder}`);
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      setSnackbar({ open: true, message: 'Error fetching tickets', severity: 'error' });
    }
  }, [sortField, sortOrder, showAssignedOnly]);

  const fetchEarnings = useCallback(async () => {
    try {
      const response = await api.get('/api/service-workers/earnings');
      setEarnings(response.data.totalEarnings || 0);
    } catch (error) {
      console.error('Error fetching earnings:', error);
      setSnackbar({ open: true, message: 'Error fetching earnings', severity: 'error' });
    }
  }, []);

  useEffect(() => {
    fetchTickets();
    fetchEarnings();
    connectSocket();

    const handleTicketUpdated = (updatedTicket) => {
      setTickets(prevTickets =>
        prevTickets.map(ticket =>
          ticket._id === updatedTicket._id ? updatedTicket : ticket
        )
      );
    };

    socketOn('ticketUpdated', handleTicketUpdated);

    return () => {
      socketOn('ticketUpdated', null);
    };
  }, [fetchTickets, fetchEarnings]);

  const handleOpenDialog = useCallback((ticket) => {
    setSelectedTicket(ticket);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleUpdateTicketStatus = useCallback(async (ticketId, newStatus) => {
    try {
      const response = await api.patch(`/api/tickets/${ticketId}`, { status: newStatus });
      setSelectedTicket(response.data);
      setSnackbar({ open: true, message: `Ticket status updated to ${newStatus}`, severity: 'success' });
      fetchTickets();
    } catch (error) {
      console.error('Error updating ticket status:', error);
      setSnackbar({ open: true, message: 'Error updating ticket status', severity: 'error' });
    }
  }, [fetchTickets]);

  const initiateRemoteSession = useCallback(async (ticketId) => {
    try {
      const response = await api.post('/api/service-workers/initiate-remote-connection', { ticketId });
      console.log('Remote session response:', response.data);
      const { roomId } = response.data;
      navigate(`/remote-session/${roomId}`);
    } catch (error) {
      console.error('Error initiating remote session:', error);
      setSnackbar({ open: true, message: 'Error initiating remote session', severity: 'error' });
    }
  }, [navigate]);

  const handleAssignTicket = useCallback(async (ticketId) => {
    try {
      await api.post(`/api/tickets/${ticketId}/assign`);
      setSnackbar({ open: true, message: 'Ticket assigned successfully', severity: 'success' });
      fetchTickets();
    } catch (error) {
      console.error('Error assigning ticket:', error);
      setSnackbar({ open: true, message: 'Error assigning ticket', severity: 'error' });
    }
  }, [fetchTickets]);

  const statusCounts = useMemo(() => {
    const counts = { open: 0, in_progress: 0, resolved: 0 };
    tickets.forEach(ticket => {
      if (counts.hasOwnProperty(ticket.status)) {
        counts[ticket.status]++;
      }
    });
    return counts;
  }, [tickets]);

  const chartData = {
    labels: ['Open', 'In Progress', 'Resolved'],
    datasets: [
      {
        label: 'Ticket Status',
        data: [statusCounts.open, statusCounts.in_progress, statusCounts.resolved],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ticket Status',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>IT Service Provider Dashboard</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" gutterBottom>Earnings</Typography>
              <Typography variant="h4">${earnings}</Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={fetchEarnings}>Refresh Earnings</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Ticket Status</Typography>
            <Bar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Tickets</Typography>
            <div className={classes.switchContainer}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showAssignedOnly}
                    onChange={() => setShowAssignedOnly(!showAssignedOnly)}
                    color="primary"
                  />
                }
                label="Show Assigned Tickets Only"
              />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel>Sort By</InputLabel>
                  <Select value={sortField} onChange={(e) => setSortField(e.target.value)}>
                    <MenuItem value="createdAt">Date Created</MenuItem>
                    <MenuItem value="suggestedPrice">Price</MenuItem>
                    <MenuItem value="priority">Priority</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel>Order</InputLabel>
                  <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <List className={classes.ticketList}>
              {tickets.map((ticket) => (
                <ListItem key={ticket._id} button onClick={() => handleOpenDialog(ticket)}>
                  <ListItemText
                    primary={ticket.title}
                    secondary={`Status: ${ticket.status} | Price: $${ticket.suggestedPrice || 'N/A'} | Priority: ${ticket.priority || 'N/A'}`}
                  />
                  {!showAssignedOnly && !ticket.assignedTo && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAssignTicket(ticket._id);
                      }}
                      color="primary"
                    >
                      Assign to Me
                    </Button>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedTicket?.title}</DialogTitle>
        <DialogContent>
          <Typography><strong>Description:</strong> {selectedTicket?.description}</Typography>
          <Typography><strong>Status:</strong> {selectedTicket?.status}</Typography>
          <Typography><strong>Suggested Price:</strong> ${selectedTicket?.suggestedPrice || 'N/A'}</Typography>
          <Typography><strong>Priority:</strong> {selectedTicket?.priority || 'N/A'}</Typography>
          <Typography><strong>Created By:</strong> {selectedTicket?.createdBy?.name}</Typography>
          <Typography><strong>Created At:</strong> {selectedTicket?.createdAt && new Date(selectedTicket.createdAt).toLocaleString()}</Typography>
        </DialogContent>
        <DialogActions>
          {selectedTicket?.status === 'open' && (
            <Button onClick={() => handleUpdateTicketStatus(selectedTicket._id, 'in_progress')} color="primary">
              Start Working
            </Button>
          )}
          {selectedTicket?.status === 'in_progress' && (
            <>
              <Button onClick={() => handleUpdateTicketStatus(selectedTicket._id, 'open')} color="secondary">
                Stop Working
              </Button>
              <Button onClick={() => handleUpdateTicketStatus(selectedTicket._id, 'resolved')} color="primary">
                Mark as Resolved
              </Button>
              <Button
                onClick={() => initiateRemoteSession(selectedTicket._id)}
                color="primary"
                variant="contained"
              >
                Initiate Remote Session
              </Button>
            </>
          )}
          {!selectedTicket?.assignedTo && (
            <Button onClick={() => handleAssignTicket(selectedTicket._id)} color="primary">
              Assign to Me
            </Button>
          )}
          <Button onClick={handleCloseDialog} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}>
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}