import io from 'socket.io-client';
import axios from 'axios';
import { config } from '../config';

let socket = null;
let isConnecting = false;
let connectionQueue = [];
const BASE_URL = 'https://it-guy.seo-top.dev';
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response?.data || error.message);
    return Promise.reject(error);
  }
);

api.getTroubleshootingGuide = () => api.get('/troubleshooting-guide');
api.getTicketHistory = (ticketId) => api.get(`/tickets/${ticketId}/history`);
api.updateTicketPriority = (ticketId, priority) => api.patch(`/tickets/${ticketId}/priority`, { priority });
api.addTicketNote = (ticketId, note) => api.post(`/tickets/${ticketId}/notes`, { note });
api.escalateTicket = (ticketId, reason, level) => api.post(`/tickets/${ticketId}/escalate`, { reason, level });

export const connectSocket = () => {
  return new Promise((resolve, reject) => {
    if (socket && socket.connected) {
      resolve(socket);
      return;
    }

    if (isConnecting) {
      connectionQueue.push({ resolve, reject });
      return;
    }

    isConnecting = true;

    const token = localStorage.getItem('token');
    if (!token) {
      isConnecting = false;
      reject(new Error('No token found'));
      return;
    }

    socket = io(config.SOCKET_URL, {
      transports: ['websocket'],
      auth: { token },
      reconnection: true,
      reconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
      reconnectionDelay: 1000,
    });

    socket.on('connect', () => {
      console.log('Socket connected successfully');
      isConnecting = false;
      reconnectAttempts = 0;
      resolve(socket);
      processQueue();
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error.message);
      reconnectAttempts++;
      if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        isConnecting = false;
        reject(new Error(`Socket connection failed after ${MAX_RECONNECT_ATTEMPTS} attempts`));
        processQueue();
      }
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // The disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error.message);
    });
  });
};

function processQueue() {
  while (connectionQueue.length > 0) {
    const { resolve, reject } = connectionQueue.shift();
    if (socket && socket.connected) {
      resolve(socket);
    } else {
      reject(new Error('Socket connection failed'));
    }
  }
}

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const socketOn = (event, callback) => {
  if (socket) {
    socket.on(event, callback);
  } else {
    console.error('Socket is not initialized');
  }
};

export const socketOff = (event, callback) => {
  if (socket) {
    socket.off(event, callback);
  } else {
    console.error('Socket is not initialized');
  }
};

export const socketEmit = (event, data) => {
  if (socket && socket.connected) {
    console.log(`Emitting socket event: ${event}`, data);
    socket.emit(event, data);
  } else {
    console.warn('Socket not initialized or not connected. Attempting to reconnect...');
    connectSocket().then(() => {
      if (socket && socket.connected) {
        console.log(`Emitting socket event after reconnection: ${event}`, data);
        socket.emit(event, data);
      } else {
        console.error('Failed to reconnect and emit event');
      }
    }).catch(error => {
      console.error('Failed to reconnect:', error.message);
    });
  }
};

export const getSocket = () => socket;
export const requestRemoteControl = (roomId) => socketEmit('requestRemoteControl', { roomId });
export const approveRemoteControl = (roomId) => socketEmit('approveRemoteControl', { roomId });
export const denyRemoteControl = (roomId) => socketEmit('denyRemoteControl', { roomId });
export const isSocketConnected = () => socket && socket.connected;

export default api;