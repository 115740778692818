import React from 'react';
import { Button, Grid, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Mic, MicOff, Videocam, VideocamOff, ScreenShare, StopScreenShare,
  CallEnd, Chat, Description, BugReport, Person,
  Build, Timeline, PriorityHigh, Note, TrendingUp, Mouse, Block
} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    margin: theme.spacing(1),
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ControlPanel = ({
  isCallStarted,
  isMuted,
  isVideoOff,
  isScreenSharing,
  isChatOpen,
  onStartCall,
  onEndCall,
  onToggleAudio,
  onToggleVideo,
  onToggleScreenShare,
  onToggleChat,
  onViewTicketDetails,
  onOpenDiagnosticTools,
  currentUserRole,
  onViewUserData,
  showUserDataButton,
  onOpenTroubleshootingGuide,
  onViewSessionHistory,
  onUpdateTicketPriority,
  onAddTicketNote,
  onEscalateTicket,
  isPeerConnected,
  onApproveRemoteControl,
  onRequestRemoteControl,
  isRemoteControlActive,
  onStopRemoteControl,
  showRemoteControlButton,
  isServiceWorker,
  showRemoteControlUI,
  isRemoteControlRequested,
}) => {
  const classes = useStyles();

  const handleRemoteControlClick = () => {
    console.log("Remote control button clicked");
    if (isRemoteControlActive) {
      onStopRemoteControl();
    } else {
      onRequestRemoteControl();
    }
  };

  const renderServiceWorkerControls = () => {
    if (currentUserRole === 'service_worker') {
      return (
        <>
          <Grid item>
            <Tooltip title="Open Troubleshooting Guide">
              <Button onClick={onOpenTroubleshootingGuide} className={classes.button}>
                <Build />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="View Session History">
              <Button onClick={onViewSessionHistory} className={classes.button}>
                <Timeline />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Update Ticket Priority">
              <Button onClick={onUpdateTicketPriority} className={classes.button}>
                <PriorityHigh />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Add Ticket Note">
              <Button onClick={onAddTicketNote} className={classes.button}>
                <Note />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Escalate Ticket">
              <Button onClick={onEscalateTicket} className={classes.button}>
                <TrendingUp />
              </Button>
            </Tooltip>
          </Grid>
        </>
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.root} justifyContent="center" alignItems="center" spacing={2}>
        {!isCallStarted ? (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onStartCall}
              startIcon={<Videocam />}
              className={classes.button}
            >
              Start Support Session
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                <Button
                  onClick={onToggleAudio}
                  className={`${classes.button} ${isMuted ? classes.activeButton : ''}`}
                >
                  {isMuted ? <MicOff /> : <Mic />}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={isVideoOff ? "Turn on camera" : "Turn off camera"}>
                <Button
                  onClick={onToggleVideo}
                  className={`${classes.button} ${isVideoOff ? classes.activeButton : ''}`}
                >
                  {isVideoOff ? <VideocamOff /> : <Videocam />}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title={isScreenSharing ? "Stop screen sharing" : "Start screen sharing"}>
                <Button
                  onClick={onToggleScreenShare}
                  className={`${classes.button} ${isScreenSharing ? classes.activeButton : ''}`}
                >
                  {isScreenSharing ? <StopScreenShare /> : <ScreenShare />}
                </Button>
              </Tooltip>
            </Grid>
            {isServiceWorker && !isRemoteControlActive && (
              <Grid item>
                <Tooltip title="Request remote control">
                  <Button
                    onClick={onRequestRemoteControl}
                    className={classes.button}
                    disabled={isRemoteControlRequested}
                  >
                    <Mouse />
                    {isRemoteControlRequested ? 'Waiting for approval' : 'Request Control'}
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {!isServiceWorker && isRemoteControlRequested && (
              <Grid item>
                <Tooltip title="Approve remote control">
                  <Button
                    onClick={onApproveRemoteControl}
                    className={classes.button}
                  >
                    Approve Control
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {isRemoteControlActive && (
              <Grid item>
                <Tooltip title="Stop remote control">
                  <Button
                    onClick={onStopRemoteControl}
                    className={classes.button}
                  >
                    <Block />
                    Stop Control
                  </Button>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip title={isChatOpen ? "Close chat" : "Open chat"}>
                <Button
                  onClick={onToggleChat}
                  className={`${classes.button} ${isChatOpen ? classes.activeButton : ''}`}
                >
                  <Chat />
                </Button>
              </Tooltip>
            </Grid>
            {showUserDataButton && (
              <Grid item>
                <Tooltip title="View User Data">
                  <Button onClick={onViewUserData} className={classes.button}>
                    <Person />
                  </Button>
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Tooltip title="View ticket details">
                <Button
                  onClick={() => {
                    console.log('View ticket details button clicked');
                    onViewTicketDetails();
                  }}
                  className={classes.button}
                >
                  <Description />
                </Button>
              </Tooltip>
            </Grid>
            {currentUserRole === 'service_worker' && (
              <Grid item>
                <Tooltip title="Open diagnostic tools">
                  <Button onClick={onOpenDiagnosticTools} className={classes.button}>
                    <BugReport />
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {renderServiceWorkerControls()}
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={onEndCall}
                startIcon={<CallEnd />}
                className={classes.button}
              >
                End Session
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default ControlPanel;