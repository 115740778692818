// RemoteSession.js
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../contexts/AuthContext';
import useWebRTC from '../hooks/useWebRTC';
import useScreenSharing from '../hooks/useScreenSharing';
import useRemoteControl from '../hooks/useRemoteControl';
import VideoStream from '../VideoStream';
import ControlPanel from '../ControlPanel';
import ErrorBoundary from '../ErrorBoundary';
import { socketOn, socketEmit } from '../services/api';
import { showNotification } from '../utils/notification';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    objectFit: 'contain',
  },
}));

const RemoteSession = () => {
  const classes = useStyles();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);

  const {
    localStream,
    remoteStream,
    isCallStarted,
    isMuted,
    isVideoOff,
    isPeerConnected,
    startCall,
    endCall,
    toggleAudio,
    toggleVideo,
    handleIncomingCall,
    sendSignal,
  } = useWebRTC(roomId);

  const {
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
  } = useScreenSharing(sendSignal);

  const {
    isControlling,
    startRemoteControl,
    stopRemoteControl,
    handleRemoteControlMessage,
  } = useRemoteControl(isPeerConnected, sendSignal);

  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  useEffect(() => {
    socketEmit('join', roomId);

    socketOn('userJoined', (userId) => {
      console.log('Another user joined the room:', userId);
      startCall();
    });

    socketOn('incomingCall', ({ signal, from }) => {
      console.log('Received incoming call from:', from);
      handleIncomingCall(signal);
    });

    socketOn('userLeft', () => {
      console.log('User left the room');
      endCall();
      navigate('/dashboard');
    });

    return () => {
      endCall();
    };
  }, [roomId, startCall, handleIncomingCall, endCall, navigate]);

  useEffect(() => {
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  const handleError = useCallback((error) => {
    console.error('RemoteSession error:', error);
    setError(error.message);
    showNotification(error.message, 'error');
  }, []);

  return (
    <ErrorBoundary onError={handleError}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.videoContainer}>
              <VideoStream
                ref={localVideoRef}
                muted
                autoPlay
                playsInline
                className={classes.video}
              />
              <Typography align="center">Your Video</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.videoContainer}>
              <VideoStream
                ref={remoteVideoRef}
                autoPlay
                playsInline
                className={classes.video}
              />
              <Typography align="center">Remote Video</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ControlPanel
              isCallStarted={isCallStarted}
              isMuted={isMuted}
              isVideoOff={isVideoOff}
              isScreenSharing={isScreenSharing}
              isControlling={isControlling}
              isPeerConnected={isPeerConnected}
              toggleAudio={toggleAudio}
              toggleVideo={toggleVideo}
              startScreenSharing={startScreenSharing}
              stopScreenSharing={stopScreenSharing}
              startRemoteControl={startRemoteControl}
              stopRemoteControl={stopRemoteControl}
              startCall={startCall}
              endCall={endCall}
              currentUserRole={currentUser.role}
            />
          </Grid>
        </Grid>
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default RemoteSession;