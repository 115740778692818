import React, { useState, useEffect, useCallback } from 'react';
import { throttle } from 'lodash';

const RemoteControlHandler = ({ isActive, onInput, isMouseControlActive, isKeyboardControlActive, currentUser }) => {
  const [serviceWorkerMouse, setServiceWorkerMouse] = useState({ x: 0, y: 0 });
  const [userMouse, setUserMouse] = useState({ x: 0, y: 0 });

  const throttledOnInput = useCallback(
    throttle((data) => {
      onInput(data);
    }, 16), // Limit to roughly 60fps
    [onInput]
  );

  const handleInput = useCallback((type, data) => {
    if (isActive) {
      throttledOnInput({ type, ...data });
    }
  }, [isActive, throttledOnInput]);

  useEffect(() => {
    if (!isActive) return;

    const handleMouseMove = (e) => {
      if (isMouseControlActive) {
        const newPos = {
          x: e.clientX / window.innerWidth,
          y: e.clientY / window.innerHeight
        };
        if (currentUser.role === 'service_worker') {
          setServiceWorkerMouse(newPos);
        } else {
          setUserMouse(newPos);
        }
        handleInput('mousemove', newPos);
      }
    };

    const handleMouseDown = (e) => {
      if (isMouseControlActive) {
        handleInput('mousedown', { button: e.button });
      }
    };

    const handleMouseUp = (e) => {
      if (isMouseControlActive) {
        handleInput('mouseup', { button: e.button });
      }
    };

    const handleKeyDown = (e) => {
      if (isKeyboardControlActive) {
        handleInput('keydown', { key: e.key, keyCode: e.keyCode });
      }
    };

    const handleKeyUp = (e) => {
      if (isKeyboardControlActive) {
        handleInput('keyup', { key: e.key, keyCode: e.keyCode });
      }
    };

    const handleWheel = (e) => {
      if (isMouseControlActive) {
        handleInput('wheel', { deltaY: e.deltaY });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isActive, isMouseControlActive, isKeyboardControlActive, handleInput, currentUser.role]);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          left: `${serviceWorkerMouse.x * 100}%`,
          top: `${serviceWorkerMouse.y * 100}%`,
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: 'red',
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          position: 'absolute',
          left: `${userMouse.x * 100}%`,
          top: `${userMouse.y * 100}%`,
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: 'blue',
          pointerEvents: 'none',
        }}
      />
    </>
  );
};

export default RemoteControlHandler;