// api.js
import axios from 'axios';
import io from 'socket.io-client';
import { showNotification } from '../utils/notification';

const BASE_URL = 'https://it-guy.seo-top.dev';

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle token expiration
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

let socket = null;

const connectSocket = () => {
  if (!socket) {
    const token = localStorage.getItem('token');
    socket = io(BASE_URL, {
      transports: ['websocket'],
      auth: {
        token: token
      },
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    socket.on('connect', () => {
      console.log('Socket connected');
      const userId = localStorage.getItem('userId');
      if (userId) {
        console.log('Joining room:', userId);
        socket.emit('join', userId);
      }
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      showNotification('Connection error. Please check your internet connection.', 'error');
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
    });

    // Add any other socket event listeners here
  }
};

const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

const socketOn = (event, callback) => {
  if (socket) {
    console.log(`Setting up listener for event: ${event}`);
    socket.off(event);
    if (callback) {
      socket.on(event, (data) => {
        console.log(`Received ${event} event:`, data);
        callback(data);
      });
    }
  } else {
    console.error('Socket is not initialized');
    showNotification('Connection not established. Please try again.', 'error');
  }
};

const socketEmit = (event, data) => {
  if (socket) {
    console.log(`Emitting ${event} event:`, data);
    socket.emit(event, data);
  } else {
    console.error('Socket is not initialized');
    showNotification('Connection not established. Please try again.', 'error');
  }
};

export { api as default, connectSocket, disconnectSocket, socketOn, socketEmit };