import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function AuthCallback() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      login(token).then((user) => {
        if (!user.role || user.role === 'new') {
          navigate('/onboarding');
        } else {
          navigate('/dashboard');
        }
      }).catch(error => {
        console.error('Login error:', error);
        navigate('/');
      });
    } else {
      console.error('No token found in URL');
      navigate('/');
    }
  }, [location, login, navigate]);

  return <div>Authenticating...</div>;
}