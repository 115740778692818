import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Typography, Paper, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, IconButton, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../contexts/AuthContext';
import useWebRTC from '../hooks/useWebRTC';
import VideoStream from './VideoStream';
import ControlPanel from './ControlPanel';
import Chat from './Chat';
import api, { socketOn, socketOff } from '../services/api';
import { toast } from 'react-toastify';
import collectUserData from '../utils/userDataCollector';
import AccessControl from '../utils/accessControl';
import ScreenRecorder from '../services/ScreenRecorder';
import RemoteControlHandler from './RemoteControlHandler';
import { showNotification } from '../utils/notification';
import { ZoomIn, ZoomOut, FullscreenExit, Fullscreen, Mouse, Keyboard } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
    backgroundColor: theme.palette.background.default,
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  controlPanel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  chatContainer: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    width: '300px',
    height: '400px',
    zIndex: 1000,
  },
  screenShareContainer: {
    marginTop: theme.spacing(2),
    height: '40vh',
    backgroundColor: theme.palette.background.default,
  },
  remoteControlContainer: {
    marginTop: theme.spacing(2),
    height: '40vh',
    backgroundColor: theme.palette.background.default,
  },
  remoteControlSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  remoteControlVideoContainer: {
    position: 'relative',
    width: '100%',
    height: '60vh',
    backgroundColor: theme.palette.background.default,
  },
  remoteControlVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  remoteControlOverlay: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
  remoteControlButtons: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
}));

const RemoteSession = () => {
  const classes = useStyles();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { currentUser, _loading: authLoading } = useAuth();
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const screenShareRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isTicketDetailsOpen, setIsTicketDetailsOpen] = useState(false);
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isUserDataDialogOpen, setIsUserDataDialogOpen] = useState(false);
  const [isAssignTicketDialogOpen, setIsAssignTicketDialogOpen] = useState(false);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isTroubleshootingGuideOpen, setIsTroubleshootingGuideOpen] = useState(false);
  const [isSessionHistoryOpen, setIsSessionHistoryOpen] = useState(false);
  const [sessionHistory, setSessionHistory] = useState([]);
  const [ticketPriority, setTicketPriority] = useState('');
  const [ticketNotes, setTicketNotes] = useState('');
  const [knowledgeBase, setKnowledgeBase] = useState([]);
  const [selectedKnowledgeArticle, setSelectedKnowledgeArticle] = useState(null);
  const [isEscalating, setIsEscalating] = useState(false);
  const [escalationReason, setEscalationReason] = useState('');
  const [escalationLevel, setEscalationLevel] = useState('');
  const [isTicketPriorityDialogOpen, setIsTicketPriorityDialogOpen] = useState(false);
  const [isTicketNoteDialogOpen, setIsTicketNoteDialogOpen] = useState(false);
  const [screenRecorder, setScreenRecorder] = useState(null);
  const [showRemoteControlDialog, setShowRemoteControlDialog] = useState(false);
  const [isRemoteControlActive, setIsRemoteControlActive] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [isKeyboardControlActive, setIsKeyboardControlActive] = useState(false);
  const [isMouseControlActive, setIsMouseControlActive] = useState(false);
  const remoteControlContainerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoom, setZoom] = useState(1);
  const remoteControlVideoRef = useRef();
  const remoteScreenShareRef = useRef();
  const peerRef = useRef(null);
  const [remoteVideoStream, setRemoteVideoStream] = useState(null);
  const [remoteScreenStream, setRemoteScreenStream] = useState(null);
  const [isRemoteControlDialogOpen, setIsRemoteControlDialogOpen] = useState(false);
  const [ticketStatus, setTicketStatus] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [computerInfo, setComputerInfo] = useState(null);
  const [pipWindow, setPipWindow] = useState(null);
  const [isPipMode, setIsPipMode] = useState(false);

  const {
    localStream,
    remoteStream,
    remoteScreenShareStream,
    isCallStarted,
    isMuted,
    isVideoOff,
    isPeerConnected,
    isScreenSharing,
    isControlling,
    error,
    chatMessages,
    isInitialized,
    isRoomReady,
    screenSharingStream,
    isRemoteControlRequested,
    connectionStatus,
    showRemoteControlUI,
    handleRemoteControlEvent,
    screenStream,
    handleStopRemoteControl,
    sendChatMessage,
    endCall,
    toggleAudio,
    toggleVideo,
    startScreenSharing,
    stopScreenSharing,
    requestRemoteControl,
    approveRemoteControl,
    denyRemoteControl,
    initializeConnection,
    sendRemoteControlInput,
    handleRequestRemoteControl,
    stopRemoteControl,
    setScreenSharingStream,
    setIsControlling,
    handleStream,
  } = useWebRTC(roomId, currentUser);

  const remoteControlStream = remoteScreenStream;

  const extractTicketId = useCallback((roomId) => {
    const parts = roomId.split('-');
    return parts[parts.length - 1];
  }, []);

  const startRecording = useCallback(() => {
    const recorder = new ScreenRecorder();
    recorder.start(screenSharingStream);
    setScreenRecorder(recorder);
  }, [screenSharingStream]);

  const stopRecording = useCallback(() => {
    if (screenRecorder) {
      const recordedBlob = screenRecorder.stop();
      // Handle the recorded blob (e.g., save it or send it to the server)
      console.log('Recorded blob:', recordedBlob);
      // Send the recorded blob to the server 
      api.post(`/api/tickets/${roomId}/screen-recording`, { recording: recordedBlob });
      toast.success('Screen recording saved successfully');
      setScreenRecorder(null);
    }
  }, [screenRecorder, roomId]);

  const fetchComputerInfo = useCallback(async () => {
    try {
      const response = await api.get('/api/computer-info');
      setComputerInfo(response.data);
    } catch (error) {
      console.error('Error fetching computer info:', error);
    }
  }, []);

  const startPictureInPicture = async () => {
    if (document.pictureInPictureEnabled) {
      try {
        await localVideoRef.current.requestPictureInPicture();
        setIsPipMode(true);
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode', error);
      }
    }
  };

  const fetchTicketDetails = useCallback(async () => {
    setIsTicketLoading(true);
    try {
      const ticketId = extractTicketId(roomId);
      console.log('Fetching ticket details for ticketId:', ticketId);

      // Add a timeout to the fetch request
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Request timed out')), 10000)
      );

      const fetchPromise = api.get(`/api/tickets/${ticketId}`);

      const response = await Promise.race([fetchPromise, timeoutPromise]);

      console.log('Ticket details response:', response.data);

      if (response.data) {
        setTicketDetails(response.data);
        setIsTicketDetailsOpen(true);
      } else {
        throw new Error('No ticket data received');
      }
    } catch (error) {
      console.error('Error fetching ticket details:', error);
      toast.error(`Failed to fetch ticket details: ${error.message}`);
    } finally {
      setIsTicketLoading(false);
    }
  }, [roomId, extractTicketId]);

  const stopPictureInPicture = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
      setPipWindow(null);
    }
  };

  const handleSelectAgent = useCallback((agent) => {
    setSelectedAgent(agent);
  }, []);

  const handleApproveRemoteControl = useCallback(async () => {
    if (isRemoteControlActive) {
      console.log('Remote control is already active');
      return;
    }
  
    try {
      console.log('User approving remote control');
      await approveRemoteControl();
      setShowRemoteControlDialog(false);
      setIsRemoteControlActive(true);
    } catch (error) {
      console.error('Error approving remote control:', error);
      showNotification('Failed to approve remote control', 'error');
    }
  }, [approveRemoteControl, isRemoteControlActive]);

  const togglePictureInPicture = useCallback(async () => {
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
      setIsPipMode(false);
    } else if (document.pictureInPictureEnabled) {
      try {
        await remoteVideoRef.current.requestPictureInPicture();
        setIsPipMode(true);
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode:', error);
      }
    }
  }, []);

  const handleRecording = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording(screenShareStream || remoteVideoStream);
    }
    setIsRecording(!isRecording);
  }, [isRecording, screenShareStream, remoteVideoStream, startRecording, stopRecording]);

  const handleRemoteControlInput = useCallback((event) => {
    if (isControlling && remoteControlContainerRef.current) {
      if (event.preventDefault && typeof event.preventDefault === 'function') {
        event.preventDefault();
      }

      const rect = remoteControlContainerRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;

      let inputData;

      switch (event.type) {
        case 'mousemove':
          if (isMouseControlActive) {
            inputData = { type: 'mousemove', x, y };
          }
          break;
        case 'mousedown':
        case 'mouseup':
          if (isMouseControlActive) {
            inputData = { type: event.type, x, y, button: event.button };
          }
          break;
        case 'wheel':
          if (isMouseControlActive) {
            inputData = { type: 'wheel', deltaX: event.deltaX, deltaY: event.deltaY };
          }
          break;
        case 'keydown':
        case 'keyup':
          if (isKeyboardControlActive) {
            inputData = { type: event.type, key: event.key, keyCode: event.keyCode };
          }
          break;
        default:
          return;
      }

      if (inputData) {
        sendRemoteControlInput(inputData);
      }
    }
  }, [isControlling, sendRemoteControlInput, isMouseControlActive, isKeyboardControlActive]);

  const handleConfirmAssignment = useCallback(async () => {
    if (selectedAgent && ticketDetails) {
      try {
        await api.post(`/api/tickets/${ticketDetails.id}/assign`, { agentId: selectedAgent.id });
        toast.success('Ticket assigned successfully');
        setIsAssignTicketDialogOpen(false);
        fetchTicketDetails(); // Refresh ticket details
      } catch (error) {
        console.error('Error assigning ticket:', error);
        toast.error('Failed to assign ticket');
      }
    }
  }, [selectedAgent, ticketDetails, fetchTicketDetails]);


  const handleViewTicketDetails = () => {
    console.log('View Ticket Details button clicked');
    fetchTicketDetails();
  };

  const handleOpenTroubleshootingGuide = useCallback(async () => {
    try {
      const response = await api.get('/api/service-workers/troubleshooting-guide');
      setKnowledgeBase(response.data);
      setIsTroubleshootingGuideOpen(true);
    } catch (error) {
      console.error('Error fetching knowledge base:', error);
      toast.error('Failed to fetch troubleshooting guide. Please try again.');
    }
  }, []);

  const handleUpdateTicketStatus = useCallback(async (newStatus) => {
    try {
      const ticketId = extractTicketId(roomId);
      console.log('Updating ticket status:', ticketId, newStatus); // Add this log
      const response = await api.patch(`/api/tickets/${ticketId}/status`, { status: newStatus });
      console.log('Server response:', response.data); // Add this log
      if (response.data && response.data.status === newStatus) {
        setTicketStatus(newStatus);
        toast.success(`Ticket status updated to ${newStatus}`);
        fetchTicketDetails();
      } else {
        throw new Error('Status update failed');
      }
    } catch (error) {
      console.error('Error updating ticket status:', error.response?.data || error.message);
      toast.error(`Failed to update ticket status: ${error.response?.data?.error || error.message}`);
    }
  }, [roomId, extractTicketId, fetchTicketDetails]);

  const handleSelectKnowledgeArticle = useCallback((article) => {
    setSelectedKnowledgeArticle(article);
  }, []);

  const handleAddTicketNote = useCallback(async () => {
    try {
      const ticketId = extractTicketId(roomId);
      await api.post(`/api/tickets/${ticketId}/notes`, { note: ticketNotes });
      setTicketNotes('');
      toast.success('Note added to the ticket');
      fetchTicketDetails();
    } catch (error) {
      console.error('Error adding ticket note:', error);
      toast.error(`Failed to add note to the ticket: ${error.response?.data?.error || error.message}`);
    }
  }, [roomId, extractTicketId, ticketNotes, fetchTicketDetails]);

  const handleEscalateTicket = useCallback(async () => {
    try {
      const ticketId = extractTicketId(roomId);
      await api.post(`/api/tickets/${ticketId}/escalate`, {
        reason: escalationReason,
        level: escalationLevel
      });
      toast.success('Ticket escalated successfully');
      setIsEscalating(false);
      fetchTicketDetails();
    } catch (error) {
      console.error('Error escalating ticket:', error);
      toast.error('Failed to escalate ticket. Please try again.');
    }
  }, [roomId, extractTicketId, escalationReason, escalationLevel, fetchTicketDetails]);

  const handleEscalate = async () => {
    try {
      const ticketId = extractTicketId(roomId);
      await api.post(`/api/tickets/${ticketId}/escalate`, {
        reason: 'Escalated from remote session',
        level: 'next_tier'
      });
      showNotification('Ticket has been escalated', 'success');
    } catch (error) {
      console.error('Failed to escalate ticket:', error);
      showNotification('Failed to escalate ticket', 'error');
    }
  };

  const handleViewSessionHistory = useCallback(async () => {
    try {
      const ticketId = extractTicketId(roomId);
      const response = await api.get(`/api/tickets/${ticketId}/history`);
      setSessionHistory(response.data);
      setIsSessionHistoryOpen(true);
    } catch (error) {
      console.error('Error fetching session history:', error);
      toast.error('Failed to fetch session history. Please try again.');
    }
  }, [roomId, extractTicketId]);

  const handleCallEnd = useCallback(() => {
    endCall();
    navigate('/dashboard');
  }, [endCall, navigate]);

  const handleSendMessage = useCallback((message) => {
    sendChatMessage(message);
    setNewMessage('');
  }, [sendChatMessage]);

  const fetchUserData = useCallback(async () => {
    try {
      const ticketId = extractTicketId(roomId);
      const response = await api.get(`/api/tickets/${ticketId}/user-data`);
      setUserData(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to fetch user data');
    }
  }, [roomId, extractTicketId]);

  const handleViewUserData = useCallback(() => {
    if (currentUser.role === 'service_worker') {
      fetchUserData();
      setIsUserDataDialogOpen(true);
    }
  }, [currentUser.role, fetchUserData]);

  const handleOpenDiagnosticTools = () => {
    if (currentUser.role === 'service_worker') {
      // Open a new window or modal for diagnostic tools
      const diagnosticWindow = window.open('', 'Diagnostic Tools', 'width=800,height=600');

      // Add content to the diagnostic window
      diagnosticWindow.document.write(`
        <h1>Diagnostic Tools</h1> 
        <button onclick="runNetworkTest()">Run Network Test</button>
        <button onclick="checkSystemInfo()">Check System Info</button>
        <div id="results"></div>
        <script>
          function runNetworkTest() {
            // Implement network test logic here
            document.getElementById('results').innerHTML = 'Running network test...';
          } 
          function checkSystemInfo() {
            // Implement system info check logic here
            document.getElementById('results').innerHTML = 'Checking system info...';
          }
        </script>
      `);
    } else {
      console.log('Diagnostic tools are only available for service workers');
    }
  };

  const sendUserData = useCallback(async () => {
    const userData = collectUserData();
    try {
      await api.post(`/api/tickets/${roomId}/user-data`, userData);
    } catch (error) {
      console.error('Error sending user data:', error);
    }
  }, [roomId]);

  const renderUserDataField = (label, value) => (
    <Typography key={label}>
      <strong>{label}:</strong> {value || 'N/A'}
    </Typography>
  );

  const handleDenyRemoteControl = useCallback(() => {
    console.log('Denying remote control');
    denyRemoteControl();
    setShowRemoteControlDialog(false);
    toast.info("Remote control request denied");
  }, [denyRemoteControl]);

  const handleMouseControl = () => {
    setIsMouseControlActive(prev => !prev);
  };

  const handleKeyboardControl = () => {
    setIsKeyboardControlActive(prev => !prev);
  };

  const handleZoomIn = () => setZoom(prevZoom => Math.min(prevZoom + 0.1, 3));
  const handleZoomOut = () => setZoom(prevZoom => Math.max(prevZoom - 0.1, 0.5));

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      remoteControlContainerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    if (!authLoading && currentUser && roomId && !isInitialized) {
      console.log('Initializing RemoteSession:', { currentUser, roomId });
      setIsLoading(false);
      initializeConnection();
    }
  }, [authLoading, currentUser, roomId, initializeConnection, isInitialized]);

  useEffect(() => {
    if (isRoomReady) {
      console.log('Room is ready. Both users should start the call.');
    }
  }, [isRoomReady]);

  useEffect(() => {
    if (isCallStarted) {
      sendUserData();
    }
  }, [isCallStarted, sendUserData]);

  useEffect(() => {
    if (localStream && localVideoRef.current) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  useEffect(() => {
    if (screenShareRef.current) {
      if (isScreenSharing && screenStream) {
        screenShareRef.current.srcObject = screenStream;
      } else if (remoteScreenStream) {
        screenShareRef.current.srcObject = remoteScreenStream;
      }
    }
  }, [isScreenSharing, screenStream, remoteScreenStream]);

  useEffect(() => {
    const handleRemoteControlRequest = ({ userId }) => {
      console.log('Remote control requested by user:', userId);
      if (currentUser.role === 'user' && !isRemoteControlActive) {
        setShowRemoteControlDialog(true);
        toast.info("Remote control requested", {
          autoClose: false,
          closeOnClick: false,
          closeButton: false,
          draggable: false,
        });
      } else if (currentUser.role === 'service_worker') {
        setIsRemoteControlDialogOpen(true);
      }
    };
  
    const handleRemoteControlApproved = () => {
      console.log('Remote control approved');
      setIsRemoteControlActive(true);
      if (currentUser.role === 'service_worker') {
        setIsControlling(true);
      }
      showNotification('Remote control approved', 'success');
    };
  
    socketOn('remoteControlRequested', handleRemoteControlRequest);
    socketOn('remoteControlApproved', handleRemoteControlApproved);
    socketOn('remoteSessionInvite', handleRequestRemoteControl);
  
    return () => {
      socketOff('remoteControlRequested', handleRemoteControlRequest);
      socketOff('remoteControlApproved', handleRemoteControlApproved);
      socketOff('remoteSessionInvite', handleRequestRemoteControl);
    };
  }, [currentUser.role, handleRequestRemoteControl, isRemoteControlActive]);

  useEffect(() => {
    if (isRemoteControlActive && currentUser.role === 'user' && !screenSharingStream) {
      const startScreenShare = async () => {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
          console.log('Screen share stream obtained:', stream);
          setScreenSharingStream(stream);
        } catch (error) {
          console.error('Error starting screen share:', error);
          showNotification('Failed to start screen sharing', 'error');
          setIsRemoteControlActive(false);
        }
      };
      startScreenShare();
    }
  }, [isRemoteControlActive, currentUser.role, screenSharingStream, setScreenSharingStream, showNotification, setIsRemoteControlActive]);

  useEffect(() => {
    const container = remoteControlContainerRef.current;
    if (container && isControlling) {
      const handleRemoteControlEvent = (event) => {
        event.preventDefault();
        handleRemoteControlInput(event);
      };

      const events = ['mousemove', 'mousedown', 'mouseup', 'wheel', 'keydown', 'keyup'];
      events.forEach(event => container.addEventListener(event, handleRemoteControlEvent, { passive: false }));

      return () => {
        events.forEach(event => container.removeEventListener(event, handleRemoteControlEvent));
      };
    }
  }, [isControlling, handleRemoteControlInput]);

  useEffect(() => {
    if (!isRemoteControlActive && screenSharingStream) {
      screenSharingStream.getTracks().forEach(track => track.stop());
      setScreenSharingStream(null);
    }
  }, [isRemoteControlActive, screenSharingStream, setScreenSharingStream]);


  if (isLoading) {
    return <div>Loading session data...</div>;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6" align="center" gutterBottom className={classes.statusMessage}>
        {connectionStatus}
      </Typography>
      {error && (
        <Typography variant="body1" align="center" gutterBottom className={classes.errorMessage}>
          Error: {error}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.videoContainer}>
            <Typography variant="h6">Local Video</Typography>
            <VideoStream ref={localVideoRef} autoPlay muted playsInline className={classes.video} srcObject={localStream} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.videoContainer}>
            <Typography variant="h6">Remote Video</Typography>
            <VideoStream ref={remoteVideoRef} autoPlay playsInline className={classes.video} srcObject={remoteVideoStream} />
          </Paper>
        </Grid>
      </Grid>
      {(isScreenSharing || remoteScreenStream) && (
        <Paper className={classes.screenShareContainer}>
          <Typography variant="h6">Screen Share</Typography>
          <VideoStream ref={screenShareRef} autoPlay playsInline className={classes.video} srcObject={isScreenSharing ? screenSharingStream : remoteScreenStream} />
        </Paper>
      )}
      <ControlPanel
        isCallStarted={isCallStarted}
        isMuted={isMuted}
        isVideoOff={isVideoOff}
        isScreenSharing={isScreenSharing}
        onToggleScreenShare={isScreenSharing ? stopScreenSharing : startScreenSharing}
        isPeerConnected={isPeerConnected}
        isControlling={isControlling}
        onEndCall={handleCallEnd}
        onToggleAudio={toggleAudio}
        onToggleVideo={toggleVideo}
        handleRemoteControlEvent={handleRemoteControlEvent}
        onRemoteControl={AccessControl.canStartRemoteControl(currentUser) ? requestRemoteControl : null}
        onToggleChat={() => setIsChatOpen(!isChatOpen)}
        onViewTicketDetails={handleViewTicketDetails}
        onOpenDiagnosticTools={handleOpenDiagnosticTools}
        currentUserRole={currentUser.role}
        showDiagnosticTools={currentUser.role === 'service_worker'}
        isRemoteControlActive={isRemoteControlActive}
        isRemoteControlRequested={isRemoteControlRequested}
        onViewUserData={handleViewUserData}
        showUserDataButton={currentUser.role === 'service_worker'}
        onOpenTroubleshootingGuide={handleOpenTroubleshootingGuide}
        onViewSessionHistory={handleViewSessionHistory}
        onUpdateTicketPriority={() => setIsTicketPriorityDialogOpen(true)}
        onAddTicketNote={() => setIsTicketNoteDialogOpen(true)}
        onEscalateTicket={() => setIsEscalating(true)}
        showRemoteControlButton={AccessControl.canStartRemoteControl(currentUser)}
        onRequestRemoteControl={handleRequestRemoteControl}
        isServiceWorker={currentUser.role === 'service_worker'}
        showRemoteControlUI={showRemoteControlUI}
        onApproveRemoteControl={handleApproveRemoteControl}
        onStopRemoteControl={handleStopRemoteControl}
        onSendRemoteControlInput={sendRemoteControlInput}
      />
      {(isRemoteControlActive || isControlling) && (
        <Paper className={classes.remoteControlSection}>
          <Typography variant="h6">Remote Control</Typography>
          <div
            ref={remoteControlContainerRef}
            className={classes.remoteControlContainer}
            tabIndex="0"
          >
            {isRemoteControlActive && (currentUser.role === 'service_worker' ? remoteScreenStream : screenSharingStream) ? (
              <VideoStream
                ref={remoteControlVideoRef}
                autoPlay
                playsInline
                className={classes.remoteControlVideo}
                srcObject={currentUser.role === 'service_worker' ? remoteScreenStream : screenSharingStream}
                style={{ transform: `scale(${zoom})` }}
              />
            ) : (
              <Typography>Waiting for screen share...</Typography>
            )}
            {currentUser.role === 'service_worker' && (
              <div className={classes.remoteControlOverlay}>
                <IconButton onClick={handleZoomIn} disabled={!isControlling}>
                  <ZoomIn />
                </IconButton>
                <IconButton onClick={handleZoomOut} disabled={!isControlling}>
                  <ZoomOut />
                </IconButton>
                <IconButton onClick={toggleFullscreen} disabled={!isControlling}>
                  {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>
                <IconButton
                  onClick={handleMouseControl}
                  color={isMouseControlActive ? "primary" : "default"}
                  disabled={!isControlling}
                >
                  <Mouse />
                </IconButton>
                <IconButton
                  onClick={handleKeyboardControl}
                  color={isKeyboardControlActive ? "primary" : "default"}
                  disabled={!isControlling}
                >
                  <Keyboard />
                </IconButton>
              </div>
            )}
          </div>
          {currentUser.role === 'service_worker' ? (
            <div>
              <Button
                onClick={isControlling ? stopRemoteControl : handleRequestRemoteControl}
                variant="contained"
                color={isControlling ? "secondary" : "primary"}
              >
                {isControlling ? "Stop Controlling" : "Request Control"}
              </Button>
              <Button onClick={handleEscalateTicket} variant="contained" color="primary">
                Escalate Issue
              </Button>
            </div>
          ) : (
            <div>
              <Button
                onClick={stopRemoteControl}
                variant="contained"
                color="secondary"
                disabled={!isRemoteControlActive}
              >
                Stop Remote Control
              </Button>
              <Button
                onClick={handleRecording}
                variant="contained"
                color={isRecording ? "secondary" : "primary"}
              >
                {isRecording ? "Stop Recording" : "Start Recording"}
              </Button>
            </div>
          )}
        </Paper>
      )}
      {isFullscreen && computerInfo && (
        <div className={classes.computerInfoOverlay}>
          <Typography>OS: {computerInfo.os}</Typography>
          <Typography>Version: {computerInfo.version}</Typography>
          <Typography>Computer Name: {computerInfo.computerName}</Typography>
        </div>
      )}

      <RemoteControlHandler
        isActive={isRemoteControlActive}
        onInput={handleRemoteControlInput}
        isMouseControlActive={isMouseControlActive}
        isKeyboardControlActive={isKeyboardControlActive}
        currentUser={currentUser}
      />
      {isChatOpen && (
        <div className={classes.chatContainer}>
          <Chat
            messages={chatMessages}
            onSendMessage={handleSendMessage}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
          />
        </div>
      )}
      <Dialog open={isTroubleshootingGuideOpen} onClose={() => setIsTroubleshootingGuideOpen(false)}>
        <DialogTitle>Knowledge Base</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h6">Articles</Typography>
              <ul>
                {knowledgeBase.map((article) => (
                  <li key={article.id} onClick={() => handleSelectKnowledgeArticle(article)}>
                    {article.title}
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item xs={8}>
              {selectedKnowledgeArticle && (
                <>
                  <Typography variant="h6">{selectedKnowledgeArticle.title}</Typography>
                  <Typography>{selectedKnowledgeArticle.content}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTroubleshootingGuideOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isSessionHistoryOpen} onClose={() => setIsSessionHistoryOpen(false)}>
        <DialogTitle>Session History</DialogTitle>
        <DialogContent>
          {sessionHistory.length > 0 ? (
            sessionHistory.map((session, index) => (
              <div key={index}>
                <Typography><strong>Date:</strong> {session.date ? new Date(session.date).toLocaleString() : 'N/A'}</Typography>
                <Typography><strong>Duration:</strong> {session.duration ? `${session.duration} minutes` : 'N/A'}</Typography>
                <Typography><strong>Agent:</strong> {session.agent || 'N/A'}</Typography>
                <Typography><strong>Notes:</strong> {session.notes || 'No notes available'}</Typography>
                <hr />
              </div>
            ))
          ) : (
            <Typography>No session history available for this ticket.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSessionHistoryOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTicketDetailsOpen} onClose={() => setIsTicketDetailsOpen(false)}>
        <DialogTitle>Ticket Details</DialogTitle>
        <DialogContent>
          {isTicketLoading ? (
            <CircularProgress />
          ) : ticketDetails ? (
            <>
              <Typography><strong>Title:</strong> {ticketDetails.title}</Typography>
              <Typography><strong>Description:</strong> {ticketDetails.description}</Typography>
              <Typography><strong>Ticket Notes:</strong></Typography>
              {ticketDetails.notes && ticketDetails.notes.length > 0 ? (
                <ul>
                  {ticketDetails.notes.map((note, index) => (
                    <li key={index}>
                      <Typography>
                        {note.content} - Added by: {ticketDetails.createdBy.name || 'Unknown'} at {new Date(note.createdAt).toLocaleString()}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No notes available</Typography>
              )}
              <Typography><strong>Priority:</strong> {ticketDetails.priority}</Typography>
              <Typography><strong>Assigned to:</strong> {ticketDetails.assignedTo?.name || 'Unassigned'}</Typography>
              <Typography><strong>Created by:</strong> {ticketDetails.createdBy?.name || 'Unknown'}</Typography>
              <Typography><strong>Suggested Price:</strong> {ticketDetails.suggestedPrice}</Typography>
              <Typography><strong>Status:</strong> {ticketDetails.status}</Typography>
              <Typography><strong>Created At:</strong> {new Date(ticketDetails.createdAt).toLocaleString()}</Typography>
              <Typography><strong>Last Updated At:</strong> {new Date(ticketDetails.updatedAt).toLocaleString()}</Typography>
              <Typography><strong>Comments:</strong> {ticketDetails.comments?.length || 0}</Typography>
              <Typography><strong>Ticket Attachments:</strong> {ticketDetails.attachments?.length || 0}</Typography>
            </>
          ) : (
            <Typography>No ticket details available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTicketDetailsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showRemoteControlDialog} onClose={handleDenyRemoteControl}>
        <DialogTitle>Remote Control Request</DialogTitle>
        <DialogContent>
          <Typography>The service worker is requesting remote control. This will share your screen. Do you want to allow this?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDenyRemoteControl} color="secondary">
            Deny
          </Button>
          <Button onClick={handleApproveRemoteControl} color="primary">
            Allow and Share Screen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isRemoteControlRequested && currentUser.role === 'user'} onClose={handleDenyRemoteControl}>
        <DialogTitle>Remote Control Request</DialogTitle>
        <DialogContent>
          <Typography>The service worker is requesting remote control. This will share your screen. Do you want to allow this?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDenyRemoteControl} color="secondary">
            Deny
          </Button>
          <Button onClick={handleApproveRemoteControl} color="primary">
            Allow and Share Screen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTicketPriorityDialogOpen} onClose={() => setIsTicketPriorityDialogOpen(false)}>
        <DialogTitle>Update Ticket Priority</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Priority</InputLabel>
            <Select
              value={ticketPriority}
              onChange={(e) => setTicketPriority(e.target.value)}
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="critical">Critical</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTicketPriorityDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleUpdateTicketStatus(ticketPriority)} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isUserDataDialogOpen} onClose={() => setIsUserDataDialogOpen(false)}>
        <DialogTitle>User Data</DialogTitle>
        <DialogContent>
          {userData ? (
            <>
              {renderUserDataField('Username', userData.username)}
              {renderUserDataField('Email', userData.email)}
              {renderUserDataField('User Agent', userData.userAgent)}
              {renderUserDataField('Color Depth', userData.colorDepth)}
              {renderUserDataField('Timezone', userData.timezone)}
              {renderUserDataField('Language', userData.language)}
              {renderUserDataField('Cookies Enabled', userData.cookiesEnabled)}
              {renderUserDataField('Do Not Track', userData.doNotTrack)}
              {renderUserDataField('Platform', userData.platform)}
              {renderUserDataField('CPU Cores', userData.cpuCores)}
              {renderUserDataField('RAM', userData.deviceMemory)}
              {renderUserDataField('Network Type', userData.networkType)}
              {renderUserDataField('Country', userData.country)}
              {renderUserDataField('Internal IP', userData.internalIp)}
              {renderUserDataField('Last Known IP', userData.lastKnownIp)}
              {renderUserDataField('Browser', userData.browserVersion)}
              {renderUserDataField('OS', userData.platform)}
              {renderUserDataField('Screen Resolution', userData.screenResolution)}
            </>
          ) : (
            <Typography>Loading user data...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUserDataDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTicketNoteDialogOpen} onClose={() => setIsTicketNoteDialogOpen(false)}>
        <DialogTitle>Add Ticket Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={ticketNotes}
            onChange={(e) => setTicketNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTicketNoteDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTicketNote} color="primary">
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isEscalating} onClose={() => setIsEscalating(false)}>
        <DialogTitle>Escalate Ticket</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Escalation Reason"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={escalationReason}
            onChange={(e) => setEscalationReason(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Escalation Level</InputLabel>
            <Select
              value={escalationLevel}
              onChange={(e) => setEscalationLevel(e.target.value)}
            >
              <MenuItem value="tier2">Tier 2 Support</MenuItem>
              <MenuItem value="tier3">Tier 3 Support</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEscalating(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEscalateTicket} color="primary">
            Escalate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAssignTicketDialogOpen} onClose={() => setIsAssignTicketDialogOpen(false)}>
        <DialogTitle>Assign Ticket</DialogTitle>
        <DialogContent>
          <Select
            value={selectedAgent}
            onChange={(e) => handleSelectAgent(e.target.value)}
            fullWidth
          >
            {availableAgents.map((agent) => (
              <MenuItem key={agent.id} value={agent}>
                {agent.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAssignTicketDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAssignment} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoteSession;