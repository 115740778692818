import { useState, useCallback, useRef } from 'react';
import { socketEmit } from '../services/api';
import { showNotification } from '../utils/notification';

const useScreenSharing = (roomId, peerRef) => {
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [screenStream, setScreenStream] = useState(null);
  const screenStreamRef = useRef(null);

  const replaceVideoTrack = useCallback((stream) => {
    if (peerRef.current) {
      const videoTrack = stream.getVideoTracks()[0];
      const sender = peerRef.current.getSenders().find(s => s.track.kind === 'video');
      if (sender) {
        sender.replaceTrack(videoTrack);
      } else {
        peerRef.current.addTrack(videoTrack, stream);
      }
    }
  }, [peerRef]);

  const startScreenSharing = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      screenStreamRef.current = stream;
      setScreenStream(stream);
      setIsScreenSharing(true);
      socketEmit('screenShare', { roomId, isSharing: true });
      replaceVideoTrack(stream);

      stream.getVideoTracks()[0].onended = stopScreenSharing;
    } catch (err) {
      console.error('Error sharing screen:', err);
      showNotification('Failed to share screen', 'error');
    }
  }, [roomId, replaceVideoTrack]);

  const stopScreenSharing = useCallback(() => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach(track => track.stop());
    }
    screenStreamRef.current = null;
    setScreenStream(null);
    setIsScreenSharing(false);
    socketEmit('screenShare', { roomId, isSharing: false });
  }, [roomId]);

  return {
    isScreenSharing,
    screenStream,
    startScreenSharing,
    stopScreenSharing,
  };
};

export default useScreenSharing;