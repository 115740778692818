import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import UserDashboard from './components/UserDashboard';
import ITDashboard from './components/ServiceWorkerDashboard';
import Onboarding from './components/Onboarding';
import TicketDetails from './components/TicketDetails';
import RemoteSession from './components/RemoteSession';
import LandingPage from './components/LandingPage';

function AppRoutes() {
  const { currentUser, loading } = useAuth();

  console.log('AppRoutes rendering', { currentUser, loading });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route 
        path="/onboarding" 
        element={
          currentUser && (!currentUser.role || currentUser.role === 'new') 
            ? <Onboarding /> 
            : <Navigate to="/dashboard" />
        } 
      />
      <Route 
        path="/dashboard" 
        element={
          currentUser 
            ? (!currentUser.role || currentUser.role === 'new') 
              ? <Navigate to="/onboarding" /> 
              : currentUser.role === 'service_worker' 
                ? <ITDashboard /> 
                : <UserDashboard />
            : <Navigate to="/" />
        } 
      />
      <Route 
        path="/tickets/:id" 
        element={currentUser ? <TicketDetails /> : <Navigate to="/" />} 
      />
      <Route 
        path="/remote-session/:roomId" 
        element={currentUser ? <RemoteSession /> : <Navigate to="/" />} 
      />
      <Route 
        path="/" 
        element={
          currentUser 
            ? (!currentUser.role || currentUser.role === 'new') 
              ? <Navigate to="/onboarding" /> 
              : <Navigate to="/dashboard" />
            : <LandingPage />
        } 
      />
    </Routes>
  );
}

export default AppRoutes;