// utils/accessControl.js
import { ROLES, PERMISSIONS, rolePermissions } from '../config/roles';

class AccessControl {
  checkPermission(user, requiredPermission) {
    if (!user || !user.role) {
      return false;
    }
    const userPermissions = rolePermissions[user.role];
    return userPermissions && userPermissions.includes(requiredPermission);
  }

  hasRole(user, requiredRole) {
    return user && user.role === requiredRole;
  }

  canStartRemoteControl(user) {
    return this.checkPermission(user, PERMISSIONS.START_REMOTE_CONTROL) || user.role === 'service_worker';
  }

  canStopRemoteControl(user) {
    return this.checkPermission(user, PERMISSIONS.STOP_REMOTE_CONTROL);
  }

  canRequestRemoteControl(user) {
    return this.checkPermission(user, PERMISSIONS.REQUEST_REMOTE_CONTROL);
  }

  canApproveRemoteControl(user) {
    return this.checkPermission(user, PERMISSIONS.APPROVE_REMOTE_CONTROL) || user.role === 'user';
  }

  canViewLogs(user) {
    return this.checkPermission(user, PERMISSIONS.VIEW_LOGS);
  }

  canManageUsers(user) {
    return this.checkPermission(user, PERMISSIONS.MANAGE_USERS);
  }

  canCreateTicket(user) {
    return this.checkPermission(user, PERMISSIONS.CREATE_TICKET);
  }

  canAssignTicket(user) {
    return this.checkPermission(user, PERMISSIONS.ASSIGN_TICKET);
  }

  canCloseTicket(user) {
    return this.checkPermission(user, PERMISSIONS.CLOSE_TICKET);
  }

  canViewAllTickets(user) {
    return this.checkPermission(user, PERMISSIONS.VIEW_ALL_TICKETS);
  }
}

export default new AccessControl();