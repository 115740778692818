// config/roles.js

export const ROLES = {
    ADMIN: 'ADMIN',
    SERVICE_WORKER: 'SERVICE_WORKER',
    USER: 'USER'
  };
  
  export const PERMISSIONS = {
    START_REMOTE_CONTROL: 'start_remote_control',
    STOP_REMOTE_CONTROL: 'STOP_REMOTE_CONTROL',
    REQUEST_REMOTE_CONTROL: 'REQUEST_REMOTE_CONTROL',
    APPROVE_REMOTE_CONTROL: 'approve_remote_control',
    VIEW_LOGS: 'VIEW_LOGS',
    MANAGE_USERS: 'MANAGE_USERS',
    CREATE_TICKET: 'CREATE_TICKET',
    ASSIGN_TICKET: 'ASSIGN_TICKET',
    CLOSE_TICKET: 'CLOSE_TICKET',
    VIEW_ALL_TICKETS: 'VIEW_ALL_TICKETS'
  };
  
  export const rolePermissions = {
    [ROLES.ADMIN]: [
      PERMISSIONS.START_REMOTE_CONTROL,
      PERMISSIONS.STOP_REMOTE_CONTROL,
      PERMISSIONS.APPROVE_REMOTE_CONTROL,
      PERMISSIONS.VIEW_LOGS,
      PERMISSIONS.MANAGE_USERS,
      PERMISSIONS.VIEW_ALL_TICKETS,
      PERMISSIONS.ASSIGN_TICKET,
      PERMISSIONS.CLOSE_TICKET
    ],
    [ROLES.SERVICE_WORKER]: [
      PERMISSIONS.START_REMOTE_CONTROL,
      PERMISSIONS.STOP_REMOTE_CONTROL,
      PERMISSIONS.APPROVE_REMOTE_CONTROL,
      PERMISSIONS.ASSIGN_TICKET,
      PERMISSIONS.CLOSE_TICKET
    ],
    [ROLES.USER]: [
      PERMISSIONS.REQUEST_REMOTE_CONTROL,
      PERMISSIONS.CREATE_TICKET
    ]
  };