import React from 'react';
import { Typography, Button, Container } from '@material-ui/core';

const BASE_URL = 'https://it-guy.seo-top.dev';

export default function LandingPage() {
  const handleGoogleLogin = () => {
    window.location.href = `${BASE_URL}/auth/google`;
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h2" gutterBottom>
        Welcome to IT Support Platform
      </Typography>
      <Typography variant="body1" paragraph>
        Get instant IT support or offer your expertise to others.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoogleLogin}>
        Login with Google
      </Button>
    </Container>
  );
}