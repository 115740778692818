import React, { createContext, useState, useContext, useEffect } from 'react';
import api, { connectSocket, disconnectSocket } from '../services/api';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUserLoggedIn();
    return () => {
      if (currentUser) {
        updateOnlineStatus(false);
      }
      disconnectSocket();
    };
  }, []);

  async function checkUserLoggedIn() {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await api.get('/auth/current_user');
        setCurrentUser(response.data);
        localStorage.setItem('userId', response.data._id);
        connectSocket();
        updateOnlineStatus(true);
      } catch (error) {
        console.error('Error checking user logged in status:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
      }
    }
    setLoading(false);
  }

  async function login(token) {
    localStorage.setItem('token', token);
    try {
      const response = await api.get('/auth/current_user');
      setCurrentUser(response.data);
      localStorage.setItem('userId', response.data._id);
      connectSocket();
      updateOnlineStatus(true);
      return response.data;
    } catch (error) {
      console.error('Error logging in:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      throw error;
    }
  }

  async function logout() {
    if (currentUser) {
      await updateOnlineStatus(false);
    }
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setCurrentUser(null);
    disconnectSocket();
    await api.get('/auth/logout');
  }

  async function updateOnlineStatus(isOnline) {
    try {
      await api.post('/api/users/update-online-status', { isOnline });
    } catch (error) {
      console.error('Error updating online status:', error);
    }
  }

  const value = {
    currentUser,
    login,
    logout,
    loading,
    checkUserLoggedIn
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, loading, checkUserLoggedIn }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}