// Frontend/services/AdaptiveQualityControl.js
export default class AdaptiveQualityControl {
    constructor() {
      this.latency = 0;
      this.packetLoss = 0;
      this.qualityScore = 1;
    }
  
    update(newLatency, newPacketLoss) {
      this.latency = newLatency;
      this.packetLoss = newPacketLoss;
      this.updateQualityScore();
    }
  
    updateQualityScore() {
      const latencyScore = Math.max(0, 1 - (this.latency / 200)); // Assume 200ms is the worst acceptable latency
      const packetLossScore = 1 - this.packetLoss;
      this.qualityScore = (latencyScore + packetLossScore) / 2;
    }
  
    getCurrentQuality() {
      return this.qualityScore;
    }
  }