import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import { Videocam, VideocamOff, MicOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[900],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
  },
  mutedIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
  },
}));

const VideoStream = React.forwardRef(({ stream, muted, autoPlay, playsInline, className, userName }, ref) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [videoState, setVideoState] = useState('loading');

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      setVideoState('playing');
    } else if (!stream) {
      setVideoState('no-video');
    }
  }, [stream]);

  const handleCanPlay = () => {
    setVideoState('playing');
  };

  const handleError = () => {
    setVideoState('error');
  };

  return (
    <div className={classes.videoContainer}>
      <video
        ref={(el) => {
          videoRef.current = el;
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            ref.current = el;
          }
        }}
        muted={muted}
        autoPlay={autoPlay}
        playsInline={playsInline}
        className={`${classes.video} ${className}`}
        onCanPlay={handleCanPlay}
        onError={handleError}
      />
      {videoState !== 'playing' && (
        <div className={classes.overlay}>
          {videoState === 'loading' && <CircularProgress />}
          {videoState === 'no-video' && (
            <>
              <VideocamOff className={classes.icon} />
              <Typography variant="h6">No video available</Typography>
            </>
          )}
          {videoState === 'error' && (
            <>
              <VideocamOff className={classes.icon} />
              <Typography variant="h6">Error loading video</Typography>
            </>
          )}
        </div>
      )}
      {muted && videoState === 'playing' && (
        <MicOff className={classes.mutedIcon} />
      )}
      <Typography variant="caption" className={classes.userName}>
        {userName}
      </Typography>
    </div>
  );
});

export default VideoStream;