// Frontend/services/KalmanFilter.js
export default class KalmanFilter {
    constructor(processNoise = 0.01, measurementNoise = 0.1) {
      this.processNoise = processNoise;
      this.measurementNoise = measurementNoise;
      this.estimatedValue = { x: 0, y: 0 };
      this.errorCovariance = 1;
    }
  
    update(measurement) {
      // Prediction
      const predictedValue = this.estimatedValue;
      const predictedCovariance = this.errorCovariance + this.processNoise;
  
      // Update
      const kalmanGain = predictedCovariance / (predictedCovariance + this.measurementNoise);
      this.estimatedValue = {
        x: predictedValue.x + kalmanGain * (measurement.x - predictedValue.x),
        y: predictedValue.y + kalmanGain * (measurement.y - predictedValue.y)
      };
      this.errorCovariance = (1 - kalmanGain) * predictedCovariance;
  
      return this.estimatedValue;
    }
  }