import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Typography, Grid, Paper, Button, TextField, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, MenuItem, Card, CardContent, Chip } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import api, { socketOn, connectSocket, disconnectSocket } from '../services/api';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  ticketList: {
    maxHeight: 400,
    overflow: 'auto',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UserDashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState({ title: '', description: '', suggestedPrice: '', priority: 'medium' });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success', action: null });
  const [activeITUsers, setActiveITUsers] = useState(0);
  const [remoteSessionInvite, setRemoteSessionInvite] = useState(null);

  const joinRemoteSession = useCallback((roomId) => {
    console.log('Joining remote session:', roomId);
    navigate(`/remote-session/${roomId}`);
    setRemoteSessionInvite(null);
  }, [navigate]);

  const handleRemoteSessionInvite = useCallback((data) => {
    console.log('Received remote session invite:', data);
    setRemoteSessionInvite(data);
    setSnackbar({
      open: true,
      message: `You've been invited to a remote session for ticket: ${data.ticketTitle}`,
      severity: 'info',
      action: (
        <Button color="inherit" size="small" onClick={() => joinRemoteSession(data.roomId)}>
          Join
        </Button>
      ),
    });
  }, [joinRemoteSession]);

  const fetchTickets = useCallback(async () => {
    try {
      const response = await api.get('/api/tickets');
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      setSnackbar({ open: true, message: 'Error fetching tickets', severity: 'error' });
    }
  }, []);

  const fetchActiveITUsers = useCallback(async () => {
    try {
      const response = await api.get('/api/users/active-it-users');
      setActiveITUsers(response.data.count);
    } catch (error) {
      console.error('Error fetching active IT users:', error);
      setSnackbar({ open: true, message: 'Error fetching active IT users', severity: 'error' });
    }
  }, []);

  useEffect(() => {
    console.log('Setting up socket connection and listeners');
    connectSocket();

    const handleTicketUpdated = (updatedTicket) => {
      console.log('Ticket updated:', updatedTicket);
      setTickets(prevTickets => 
        prevTickets.map(ticket => 
          ticket._id === updatedTicket._id ? updatedTicket : ticket
        )
      );
    };

    socketOn('ticketUpdated', handleTicketUpdated);
    socketOn('remoteSessionInvite', handleRemoteSessionInvite);

    fetchTickets();
    fetchActiveITUsers();

    const interval = setInterval(fetchActiveITUsers, 30000);

    return () => {
      console.log('Cleaning up socket listeners');
      clearInterval(interval);
      socketOn('ticketUpdated', null);
      socketOn('remoteSessionInvite', null);
      disconnectSocket();
    };
  }, [fetchTickets, fetchActiveITUsers, handleRemoteSessionInvite]);

  const handleCreateTicket = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/api/tickets', newTicket);
      setTickets([...tickets, response.data]);
      setNewTicket({ title: '', description: '', suggestedPrice: '', priority: 'medium' });
      setSnackbar({ open: true, message: 'Ticket created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating ticket:', error);
      setSnackbar({ open: true, message: 'Error creating ticket: ' + error.response.data.error, severity: 'error' });
    }
  };

  const handleOpenDialog = (ticket) => {
    setSelectedTicket(ticket);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteTicket = async (ticketId) => {
    try {
      await api.delete(`/api/tickets/${ticketId}`);
      setSnackbar({ open: true, message: 'Ticket deleted successfully', severity: 'success' });
      fetchTickets();
    } catch (error) {
      console.error('Error deleting ticket:', error);
      setSnackbar({ open: true, message: 'Error deleting ticket', severity: 'error' });
    }
  };

  const statusCounts = useMemo(() => {
    const counts = { open: 0, in_progress: 0, resolved: 0 };
    tickets.forEach(ticket => {
      if (counts.hasOwnProperty(ticket.status)) {
        counts[ticket.status]++;
      }
    });
    return counts;
  }, [tickets]);

  const chartData = {
    labels: ['Open', 'In Progress', 'Resolved'],
    datasets: [
      {
        label: 'Ticket Status',
        data: [statusCounts.open, statusCounts.in_progress, statusCounts.resolved],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ticket Status',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">User Dashboard</Typography>
          <Chip label={`Active IT Service Users: ${activeITUsers}`} color="primary" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" gutterBottom>Create New Ticket</Typography>
              <form onSubmit={handleCreateTicket}>
                <TextField
                  fullWidth
                  label="Title"
                  value={newTicket.title}
                  onChange={(e) => setNewTicket({ ...newTicket, title: e.target.value })}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={newTicket.description}
                  onChange={(e) => setNewTicket({ ...newTicket, description: e.target.value })}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
                <TextField
                  fullWidth
                  label="Suggested Price ($)"
                  value={newTicket.suggestedPrice}
                  onChange={(e) => setNewTicket({ ...newTicket, suggestedPrice: e.target.value })}
                  margin="normal"
                  type="number"
                  required
                />
                <TextField
                  fullWidth
                  select
                  label="Priority"
                  value={newTicket.priority}
                  onChange={(e) => setNewTicket({ ...newTicket, priority: e.target.value })}
                  margin="normal"
                  required
                >
                  <MenuItem value="low">Low</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                  <MenuItem value="urgent">Urgent</MenuItem>
                </TextField>
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }}>
                  Create Ticket
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Ticket Status</Typography>
            <Bar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>My Tickets</Typography>
            <List className={classes.ticketList}>
              {tickets.map((ticket) => (
                <ListItem key={ticket._id} button onClick={() => handleOpenDialog(ticket)}>
                  <ListItemText
                    primary={ticket.title}
                    secondary={`Status: ${ticket.status} | Price: $${ticket.suggestedPrice || 'N/A'} | Priority: ${ticket.priority || 'N/A'}`}
                  />
                  {ticket.status === 'open' && (
                    <Button onClick={(e) => { e.stopPropagation(); handleDeleteTicket(ticket._id); }} color="secondary">
                      Delete
                    </Button>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedTicket?.title}</DialogTitle>
        <DialogContent>
          <Typography><strong>Description:</strong> {selectedTicket?.description}</Typography>
          <Typography><strong>Status:</strong> {selectedTicket?.status}</Typography>
          <Typography><strong>Suggested Price:</strong> ${selectedTicket?.suggestedPrice}</Typography>
          <Typography><strong>Priority:</strong> {selectedTicket?.priority}</Typography>
          <Typography><strong>Created At:</strong> {selectedTicket?.createdAt && new Date(selectedTicket.createdAt).toLocaleString()}</Typography>
          {selectedTicket?.resolvedAt && (
            <Typography><strong>Resolved At:</strong> {new Date(selectedTicket.resolvedAt).toLocaleString()}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={!!remoteSessionInvite} 
        onClose={() => setRemoteSessionInvite(null)}
      >
        <DialogTitle>Remote Session Invitation</DialogTitle>
        <DialogContent>
          <Typography>
            An IT support agent is inviting you to a remote session for ticket: {remoteSessionInvite?.ticketTitle}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoteSessionInvite(null)} color="primary">
            Decline
          </Button>
          <Button onClick={() => joinRemoteSession(remoteSessionInvite.roomId)} color="primary" autoFocus>
            Join Session
          </Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        action={snackbar.action}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}