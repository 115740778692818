import { useState, useEffect, useCallback, useRef } from 'react';
import Peer from 'simple-peer';
import { connectSocket, socketEmit, socketOn, socketOff, requestRemoteControl, approveRemoteControl, denyRemoteControl } from '../services/api';
import { showNotification } from '../utils/notification';
import useRemoteControl from './useRemoteControl';
import InputManager from '../services/InputManager';
import useScreenSharing from './useScreenSharing';

const useWebRTC = (roomId, currentUser) => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isPeerConnected, setIsPeerConnected] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [error, setError] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('Initializing...');
  const [chatMessages, setChatMessages] = useState([]);
  const peerRef = useRef(null);
  const socketRef = useRef();
  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  const [isRoomReady, setIsRoomReady] = useState(false);
  const [isInitiator, setIsInitiator] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [remoteReady, setRemoteReady] = useState(false);
  const pendingCandidates = useRef([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [screenSharingStream, setScreenSharingStream] = useState(null);
  const remoteControlRef = useRef(null);
  const screenStreamRef = useRef(null);
  const inputManagerRef = useRef(null);
  const [showRemoteControlUI, setShowRemoteControlUI] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [isRemoteControlRequested, setIsRemoteControlRequested] = useState(false);
  const [isRemoteControlActive, setIsRemoteControlActive] = useState(false);
  const dataChannelRef = useRef(null);
  const [remoteVideoStream, setRemoteVideoStream] = useState(null);
  const [remoteScreenStream, setRemoteScreenStream] = useState(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [remoteControlStream, setRemoteControlStream] = useState(null);
  const [showRemoteControlDialog, setShowRemoteControlDialog] = useState(false);
  const [isControlling, setIsControlling] = useState(false);

  const {
    handleRemoteControl,
    handleRemoteControlClick,
    handleRemoteControlScroll,
    remoteControlStreamRef,
    hasJoinedRoom,
  } = useRemoteControl(roomId, currentUser.role, peerRef);

  const {
    screenStream,
    setScreenStream,
  } = useScreenSharing(roomId, peerRef);



  const getComputerInfo = useCallback(async () => {
    try {
      const response = await api.get('/api/computer-info');
      return response.data;
    } catch (error) {
      console.error('Error fetching computer info:', error);
      return null;
    }
  }, []);

  //Remote Control
  const handleRemoteControlData = useCallback((data) => {
    if (isRemoteControlActive) {
      switch (data.type) {
        case 'mousemove':
          window.dispatchEvent(new MouseEvent('mousemove', {
            clientX: data.x * window.innerWidth,
            clientY: data.y * window.innerHeight,
            view: window,
            bubbles: true
          }));
          break;
        case 'mousedown':
        case 'mouseup':
          window.dispatchEvent(new MouseEvent(data.type, {
            clientX: data.x * window.innerWidth,
            clientY: data.y * window.innerHeight,
            button: data.button,
            view: window,
            bubbles: true
          }));
          break;
        case 'wheel':
          window.dispatchEvent(new WheelEvent('wheel', {
            deltaX: data.deltaX,
            deltaY: data.deltaY,
            view: window,
            bubbles: true
          }));
          break;
        case 'keydown':
        case 'keyup':
          window.dispatchEvent(new KeyboardEvent(data.type, {
            key: data.key,
            keyCode: data.keyCode,
            which: data.keyCode,
            view: window,
            bubbles: true
          }));
          break;
      }
    }
  }, [isRemoteControlActive]);

  const denyRemoteControl = useCallback(() => {
    socketEmit('denyRemoteControl', { roomId });
    setIsRemoteControlRequested(false);
  }, [roomId]);

  const stopRemoteControl = useCallback(() => {
    console.log('Stopping remote control');
    socketEmit('stopRemoteControl', { roomId });
    setIsRemoteControlActive(false);
    setIsControlling(false);
    if (currentUser.role === 'user' && screenSharingStream) {
      screenSharingStream.getTracks().forEach(track => track.stop());
      setScreenSharingStream(null);
    }
    showNotification('Remote control stopped', 'info');
  }, [roomId, currentUser.role, screenSharingStream]);

  const approveRemoteControl = useCallback(() => {
    console.log('Approving remote control in useWebRTC');
    socketEmit('approveRemoteControl', { roomId });
  }, [roomId]);

  const startRemoteControl = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      if (remoteControlRef.current) {
        remoteControlRef.current.srcObject = stream;
        setRemoteControlStream(stream);
      }
      approveRemoteControl();
    } catch (error) {
      console.error('Error starting remote control:', error);
      showNotification('Failed to start remote control', 'error');
    }
  }, [approveRemoteControl]);

  const requestRemoteControl = useCallback(() => {
    socketEmit('requestRemoteControl', { roomId });
    setIsRemoteControlRequested(true);
    showNotification('Remote control requested', 'info');
  }, [roomId]);

  const startScreenSharing = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      setScreenSharingStream(stream);
      setIsScreenSharing(true);
      if (peerRef.current && peerRef.current.connected) {
        stream.getTracks().forEach(track => {
          peerRef.current.addTrack(track, stream);
        });
      }
      return stream;
    } catch (error) {
      console.error('Error starting screen share:', error);
      showNotification('Failed to start screen sharing', 'error');
      throw error;
    }
  }, []);

  const sendRemoteControlInput = useCallback((inputData) => {
    if (peerRef.current && peerRef.current.connected) {
      peerRef.current.send(JSON.stringify({ type: 'remoteControl', data: inputData }));
    }
  }, []);

  const sendRemoteControlData = useCallback((data) => {
    if (peerRef.current && isControlling) {
      peerRef.current.send(JSON.stringify(data));
    }
  }, [isControlling]);

  const stopScreenSharing = useCallback(() => {
    if (screenSharingStream) {
      screenSharingStream.getTracks().forEach(track => track.stop());
      setScreenSharingStream(null);
      setIsScreenSharing(false);
    }
  }, [screenSharingStream]);

  const startRemoteControlStream = useCallback(async () => {
    try {
      console.log('Starting remote control stream');
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { displaySurface: 'monitor', cursor: 'always' },
        audio: false
      });

      setScreenSharingStream(stream);
      setIsRemoteControlActive(true);

      if (peerRef.current && peerRef.current.connected) {
        stream.getTracks().forEach(track => {
          peerRef.current.addTrack(track, stream);
        });
      }

      // Get and send computer info
      const computerInfo = await getComputerInfo();
      if (computerInfo) {
        socketEmit('computerInfo', { roomId, computerInfo });
      }

      console.log('Remote control stream started successfully');
    } catch (error) {
      console.error('Error starting remote control stream:', error);
      showNotification('Failed to start remote control stream', 'error');
    }
  }, [roomId, getComputerInfo]);

  const handleRequestRemoteControl = useCallback(() => {
    console.log('Requesting remote control');
    if (!isRemoteControlRequested && !isRemoteControlActive) {
      requestRemoteControl();
      setIsRemoteControlRequested(true);
      showNotification('Remote control requested', 'info');
    }
  }, [requestRemoteControl, isRemoteControlRequested, isRemoteControlActive]);

  const handleIncomingStream = useCallback((stream) => {
    const videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      if (videoTrack.label.toLowerCase().includes('screen')) {
        console.log('Received screen sharing stream');
        setRemoteScreenStream(stream);
      } else {
        console.log('Received remote camera stream');
        setRemoteStream(stream);
      }
    }
  }, []);

  const handleDenyRemoteControl = useCallback(() => {
    socketEmit('denyRemoteControl', { roomId });
    setIsRemoteControlRequested(false);
    showNotification('Remote control request denied', 'info');
  }, [roomId]);

  const handleStopRemoteControl = useCallback(() => {
    socketEmit('stopRemoteControl', { roomId });
    setIsRemoteControlActive(false);
    setIsControlling(false);
    if (screenShareStream) {
      screenShareStream.getTracks().forEach(track => track.stop());
    }
    setScreenShareStream(null);
    showNotification('Remote control stopped', 'info');
  }, [roomId, screenShareStream]);

  const handleRemoteControlInput = useCallback((event) => {
    if (isControlling && remoteControlContainerRef.current) {
      const rect = remoteControlContainerRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;

      let inputData;

      switch (event.type) {
        case 'mousemove':
          inputData = { type: 'mousemove', x, y };
          break;
        case 'mousedown':
        case 'mouseup':
          inputData = { type: event.type, x, y, button: event.button };
          break;
        case 'wheel':
          inputData = { type: 'wheel', deltaX: event.deltaX, deltaY: event.deltaY };
          break;
        case 'keydown':
        case 'keyup':
          inputData = { type: event.type, key: event.key, keyCode: event.keyCode };
          break;
        default:
          return;
      }

      if (inputData) {
        socketEmit('remoteControlInput', { roomId, inputData });
      }
    }
  }, [isControlling, roomId]);

  const handleApproveRemoteControl = useCallback(async () => {
    if (isRemoteControlActive) {
      console.log('Remote control is already active');
      return;
    }
    try {
      console.log('Approving remote control');
      setIsRemoteControlActive(true);
      setIsControlling(currentUser.role === 'service_worker');
      approveRemoteControl();
      if (currentUser.role === 'user') {
        await startScreenSharing();
      }
    } catch (error) {
      console.error('Error approving remote control:', error);
      showNotification('Failed to approve remote control', 'error');
      setIsRemoteControlActive(false);
      setIsControlling(false);
    }
  }, [currentUser.role, approveRemoteControl, startScreenSharing]);

  const handleRemoteControlMouseMove = useCallback((event) => {
    if (isRemoteControlActive && remoteControlRef.current) {
      const rect = remoteControlRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      sendRemoteControlInput({ action: 'move', x, y });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlMouseDown = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'click', button: event.button, clickType: 'down' });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlMouseUp = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'click', button: event.button, clickType: 'up' });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyDown = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'keydown', key: event.key, keyCode: event.keyCode });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyUp = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'keyup', key: event.key, keyCode: event.keyCode });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyPress = useCallback((event) => {
    if (isControlling && peerRef.current) {
      const key = event.key;
      peerRef.current.send(JSON.stringify({ type: 'remoteControl', action: 'key', key }));
    }
  }, [isControlling]);

  //END REMOTE CONTROL

  //Peer Connection
  const createPeer = useCallback((isInitiator) => {
    console.log('Creating peer, initiator:', isInitiator);
    if (peerRef.current) {
      peerRef.current.destroy();
    }

    const peer = new Peer({
      initiator: isInitiator,
      trickle: false,
      stream: localStreamRef.current,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' }
        ]
      }
    });

    peer.on('signal', (data) => {
      console.log('Peer signaling');
      socketEmit('signal', { roomId, signal: data });
    });

    peer.on('stream', handleIncomingStream);


    peer.on('data', (data) => {
      const parsedData = JSON.parse(data.toString());
      if (parsedData.type === 'remoteControl') {
        handleRemoteControlData(parsedData.data);
      }
    });

    peer.on('connect', () => {
      console.log('Peer connected');
      setIsPeerConnected(true);
      setIsCallStarted(true);
      setConnectionStatus('Call in progress');
      dataChannelRef.current = peer;
    });

    peer.on('error', (err) => {
      console.error('Peer error:', err);
      setError(`Peer connection error: ${err.message}`);
      showNotification(`Peer connection error: ${err.message}`, 'error');
    });

    peer.on('close', () => {
      console.log('Peer connection closed');
      setIsPeerConnected(false);
      setIsCallStarted(false);
      setConnectionStatus('Call ended');
    });

    peerRef.current = peer;
    return peer;
  }, [roomId, handleIncomingStream, handleRemoteControlData]);

  const handleIncomingCall = useCallback((incomingSignal) => {
    console.log('Handling incoming call');
    if (localStreamRef.current) {
      const peer = createPeer(false);
      peer.signal(incomingSignal);
    } else {
      console.error('Local stream not available');
      setError('Local stream not available. Please check your camera and microphone permissions.');
      showNotification('Local stream not available. Please check your camera and microphone permissions.', 'error');
    }
  }, [createPeer]);

  const handleSignal = useCallback((data) => {
    console.log('Received signal');
    if (peerRef.current) {
      peerRef.current.signal(data.signal);
    } else {
      console.warn('Received signal but peer is not initialized');
      pendingCandidates.current.push(data.signal);
    }
  }, []);

  const handleUserJoined = useCallback((user) => {
    console.log('User joined:', user);
    setRemoteUser(user);
    setConnectionStatus('Remote user joined. Waiting for room to be ready...');
  }, []);

  const handleRoomReady = useCallback(({ initiator }) => {
    console.log('Room is ready for WebRTC connection, initiator:', initiator);
    setIsRoomReady(true);
    setConnectionStatus('Room is ready. Starting call...');
    const isInitiator = currentUser.role === 'service_worker';
    setIsInitiator(isInitiator);
    startCall(isInitiator);
  }, [currentUser.role]);

  const handleUserLeft = useCallback((userId) => {
    console.log('User left:', userId);
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    setConnectionStatus('Remote user left. Call ended.');
  }, []);

  const startCall = useCallback((isInitiator) => {
    console.log('Starting call, initiator:', isInitiator);
    if (!localStreamRef.current) {
      console.error('Local stream not available');
      setError('Local stream not available. Please check your camera and microphone permissions.');
      showNotification('Local stream not available. Please check your camera and microphone permissions.', 'error');
      return;
    }
    const peer = createPeer(isInitiator);
    peerRef.current = peer;
    setConnectionStatus('Establishing peer connection...');
  }, [createPeer]);

  const endCall = useCallback(() => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    setIsCallStarted(false);
    setIsPeerConnected(false);
    if (screenStream) {
      screenStream.getTracks().forEach(track => track.stop());
      setScreenStream(null);
      setIsScreenSharing(false);
    }
  }, [screenStream]);

  const setupSocketListeners = useCallback(() => {
    if (!socketRef.current) return;

    const listeners = {
      userJoined: handleUserJoined,
      roomReady: handleRoomReady,
      signal: handleSignal,
      userLeft: handleUserLeft,
      initiateCall: () => {
        console.log('Call initiation request received');
        setError(null); // Clear any previous errors
        if (currentUser.role !== 'service_worker') {
          startCall();
        }
      },
      initiator: (isInit) => {
        console.log('Received initiator status:', isInit);
        setIsInitiator(isInit);
      },
      remoteReady: () => {
        console.log('Remote user is ready');
        setRemoteReady(true);
      },
      bothReady: () => {
        console.log('Both users are ready. Starting call...');
        setConnectionStatus('Both users ready. Starting call...');
        startCall();
      },
      incomingCall: (data) => {
        console.log('Incoming call:', data);
        setError(null); // Clear any previous errors
        handleIncomingCall(data.signal);
      },
      chatMessage: (message) => {
        console.log('Received chat message:', message);
        setChatMessages(prevMessages => [...prevMessages, message]);
      },
      remoteControlRequest: handleRequestRemoteControl,
      remoteControlApproved: () => {
        console.log('Remote control approved');
        setIsRemoteControlActive(true);
        setIsControlling(currentUser.role === 'service_worker');
        showNotification('Remote control approved', 'success');
      },
      remoteControlDenied: () => {
        console.log('Remote control denied');
        setError('Remote control request was denied.');
        showNotification('Remote control request was denied.', 'error');
      },
      remoteControlStopped: stopRemoteControl,
      disconnect: () => {
        console.log('Socket disconnected');
        setIsSocketConnected(false);
        setError('Disconnected from server. Trying to reconnect...');
        showNotification('Disconnected from server. Trying to reconnect...', 'error');
        initializeConnection();
      }
    };

    Object.entries(listeners).forEach(([event, handler]) => {
      socketOn(event, handler);
    });

    return () => {
      Object.keys(listeners).forEach((event) => {
        socketOff(event);
      });
    };
  }, [handleUserJoined, handleRoomReady, handleSignal, handleUserLeft, requestRemoteControl, approveRemoteControl, stopRemoteControl]);

  const initializeConnection = useCallback(async () => {
    try {
      console.log('Initializing WebRTC connection...');
      const socket = await connectSocket();
      socketRef.current = socket;
      setIsSocketConnected(true);
      setupSocketListeners();

      if (!localStreamRef.current) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720, facingMode: 'user' },
          audio: { echoCancellation: true, noiseSuppression: true }
        });
        localStreamRef.current = stream;
        setLocalStream(stream);
      }

      const joinRoomData = {
        roomId,
        userId: currentUser._id,
        role: currentUser.role,
        name: currentUser.name
      };
      console.log('Emitting joinRoom event with:', joinRoomData);
      socketEmit('joinRoom', joinRoomData);

      console.log('WebRTC connection initialized successfully');
      setError(null);
      setConnectionStatus('Waiting for remote user to join...');
    } catch (error) {
      console.error('Error initializing WebRTC connection:', error);
      setError(`Failed to initialize connection: ${error.message}`);
      showNotification('Failed to initialize connection. Please check your internet connection and try again.', 'error');
    }
  }, [roomId, currentUser, setupSocketListeners]);

  const toggleAudio = useCallback(() => {
    if (localStreamRef.current) {
      const audioTrack = localStreamRef.current.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsMuted(!audioTrack.enabled);
      }
    }
  }, []);

  const toggleVideo = useCallback(() => {
    if (localStreamRef.current) {
      const videoTrack = localStreamRef.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoOff(!videoTrack.enabled);
      }
    }
  }, []);

  const sendChatMessage = useCallback((message) => {
    console.log('Sending chat message');
    const newMessage = {
      content: message,
      sender: currentUser.name,
      timestamp: new Date().toISOString()
    };
    socketEmit('chatMessage', { roomId, message: newMessage });
  }, [roomId, currentUser.name]);

  const setLocalReady = useCallback(() => {
    setIsReady(true);
    socketEmit('ready', { roomId });
    console.log('Emitted ready event');
  }, [roomId]);

  const handleStream = useCallback((stream) => {
    const videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      if (videoTrack.label.toLowerCase().includes('screen')) {
        setRemoteScreenStream(stream);
      } else {
        setRemoteVideoStream(stream);
      }
    }
  }, []);

  useEffect(() => {
    const initializeLocalStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setLocalStream(stream);
        localStreamRef.current = stream;
      } catch (error) {
        console.error('Error getting local stream:', error);
        showNotification('Failed to access camera and microphone', 'error');
      }
    };

    initializeLocalStream();
  }, []);

  useEffect(() => {
    if (isRemoteControlActive && currentUser.role === 'user' && !screenSharingStream) {
      const startScreenShare = async () => {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
          console.log('Screen share stream obtained:', stream);
          setScreenSharingStream(stream);
          if (peerRef.current && peerRef.current.connected) {
            stream.getTracks().forEach(track => {
              peerRef.current.addTrack(track, stream);
            });
          }
        } catch (error) {
          console.error('Error starting screen share:', error);
          showNotification('Failed to start screen sharing', 'error');
          setIsRemoteControlActive(false);
        }
      };
      startScreenShare();
    }
  }, [isRemoteControlActive, currentUser.role, screenSharingStream]);

  useEffect(() => {
    if (peerRef.current) {
      const handleStream = (stream) => {
        console.log('Received stream:', stream);
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
          if (videoTrack.label.toLowerCase().includes('screen')) {
            console.log('Setting remote screen stream');
            setRemoteScreenStream(stream);
            setIsScreenSharing(true);
          } else {
            console.log('Setting remote video stream');
            setRemoteVideoStream(stream);
            setRemoteStream(stream);
          }
        }
      };

      const handleData = (data) => {
        try {
          const parsedData = JSON.parse(data);
          if (parsedData.type === 'remoteControl') {
            handleRemoteControlInput(parsedData.data);
          }
        } catch (error) {
          console.error('Error parsing peer data:', error);
        }
      };

      peerRef.current.on('stream', handleStream);
      peerRef.current.on('data', handleData);

      return () => {
        peerRef.current.off('stream', handleStream);
        peerRef.current.off('data', handleData);
      };
    }
  }, [handleRemoteControlInput]);

  useEffect(() => {
    if (!isRemoteControlActive && screenSharingStream) {
      screenSharingStream.getTracks().forEach(track => track.stop());
      setScreenSharingStream(null);
    }
  }, [isRemoteControlActive, screenSharingStream]);

  useEffect(() => {
    const handleRemoteControlDenied = () => {
      console.log('Remote control denied');
      setIsRemoteControlRequested(false);
      setIsControlling(false);
      showNotification('Remote control request denied', 'info');
    };

    const handleRemoteControlApproved = () => {
      console.log('Remote control approved');
      setIsRemoteControlActive(true);
      setIsControlling(currentUser.role === 'service_worker');
      showNotification('Remote control approved', 'success');
    };
    
    const handleRemoteControlStopped = () => {
      console.log('Remote control stopped');
      setIsRemoteControlActive(false);
      setIsControlling(false);
      if (screenShareStream) {
        screenShareStream.getTracks().forEach(track => track.stop());
      }
      setScreenShareStream(null);
      showNotification('Remote control stopped', 'info');
    };

    const handleChatMessage = (message) => {
      console.log('Received chat message:', message);
      setChatMessages(prevMessages => [...prevMessages, message]);
    };

    socketOn('remoteControlRequested', handleRequestRemoteControl);
    socketOn('remoteControlApproved', handleApproveRemoteControl);
    socketOn('remoteControlDenied', handleRemoteControlDenied);
    socketOn('remoteControlStopped', handleRemoteControlStopped);
    socketOn('remoteControlApproved', handleRemoteControlApproved);
    socketOn('chatMessage', handleChatMessage);

    return () => {
      socketOff('remoteControlRequested', handleRequestRemoteControl);
      socketOff('remoteControlApproved', handleApproveRemoteControl);
      socketOff('remoteControlDenied', handleRemoteControlDenied);
      socketOff('remoteControlApproved', handleRemoteControlApproved);
      socketOff('remoteControlStopped', handleRemoteControlStopped);
      socketOff('chatMessage', handleChatMessage);
    };
  }, [currentUser.role, handleRequestRemoteControl, handleApproveRemoteControl, screenShareStream, showNotification]);

  useEffect(() => {
    if (currentUser && currentUser.id && roomId && !isInitialized) {
      console.log('Initializing connection');
      initializeConnection();
      setIsInitialized(true);
    }
  }, [currentUser, roomId, initializeConnection, isInitialized]);

  useEffect(() => {
    if (peerRef.current && pendingCandidates.current.length > 0) {
      console.log('Processing pending candidates');
      pendingCandidates.current.forEach(signal => peerRef.current.signal(signal));
      pendingCandidates.current = [];
    }
  }, []);

  useEffect(() => {
    if (remoteControlStream && peerRef.current && peerRef.current.connected) {
      console.log('Adding remote control stream to peer connection');
      peerRef.current.addStream(remoteControlStream);
    }
  }, [remoteControlStream]);

  useEffect(() => {
    if (isRoomReady && !isPeerConnected) {
      startCall(currentUser.role === 'service_worker');
    }
  }, [isRoomReady, isPeerConnected, startCall, currentUser.role]);

  return {
    requestRemoteControl,
    approveRemoteControl,
    denyRemoteControl,
    stopRemoteControl,
    sendRemoteControlInput,
    sendChatMessage,
    handleRemoteControlScroll,
    handleRemoteControlClick,
    handleRemoteControlKeyPress,
    handleRemoteControlData,
    startCall,
    endCall,
    setLocalReady,
    toggleAudio,
    toggleVideo,
    startScreenSharing,
    stopScreenSharing,
    sendRemoteControlData,
    initializeConnection,
    handleRemoteControlMouseMove,
    handleRemoteControlMouseDown,
    handleRemoteControlMouseUp,
    handleRemoteControlKeyDown,
    handleRemoteControlKeyUp,
    startRemoteControl,
    handleRequestRemoteControl,
    handleRemoteControlInput,
    setConnectionStatus,
    startRemoteControlStream,
    handleApproveRemoteControl,
    handleStopRemoteControl,
    handleDenyRemoteControl,
    setIsRemoteControlRequested,
    setScreenShareStream,
    handleStream,
    setScreenSharingStream,
    setIsControlling,
    isScreenSharing,
    remoteVideoStream,
    remoteScreenStream,
    showRemoteControlUI,
    localStream,
    remoteStream,
    remoteControlStream,
    screenStream,
    screenSharingStream,
    isCallStarted,
    isMuted,
    isVideoOff,
    isPeerConnected,
    isScreenSharing,
    isControlling,
    isRemoteControlRequested,
    isRemoteControlActive,
    error,
    isSocketConnected,
    isRoomReady,
    isInitiator,
    remoteUser,
    remoteStreamRef,
    chatMessages: chatMessages || [],
    connectionStatus,
    isReady,
    remoteReady,
    screenShareStream,
  };
};

export default useWebRTC;