import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper, List, ListItem, ListItemText, Typography, 
  TextField, Button, Divider 
} from '@material-ui/core';
import { Send } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 400,
  },
  messageList: {
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: '70%',
  },
  sentMessage: {
    backgroundColor: theme.palette.primary.light,
    alignSelf: 'flex-end',
  },
  receivedMessage: {
    backgroundColor: theme.palette.grey[300],
    alignSelf: 'flex-start',
  },
  inputArea: {
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
}));

const Chat = ({ messages = [], onSendMessage, newMessage, setNewMessage }) => {
  const classes = useStyles();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  return (
    <Paper className={classes.root}>
      <List className={classes.messageList}>
        {messages.map((message, index) => (
          <ListItem 
            key={index} 
            className={`${classes.message} ${
              message.sender === 'You' ? classes.sentMessage : classes.receivedMessage
            }`}
          >
            <ListItemText
              primary={
                <Typography component="span" variant="body2" color="textPrimary">
                  {message.sender}
                </Typography>
              }
              secondary={
                <>
                  <Typography component="span" variant="body1">
                    {message.content}
                  </Typography>
                  <Typography component="span" variant="caption" display="block">
                    {new Date(message.timestamp).toLocaleTimeString()}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
        <div ref={messagesEndRef} />
      </List>
      <Divider />
      <form onSubmit={handleSendMessage} className={classes.inputArea}>
        <TextField
          className={classes.input}
          variant="outlined"
          size="small"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<Send />}
        >
          Send
        </Button>
      </form>
    </Paper>
  );
};

export default Chat;