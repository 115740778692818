// useRemoteControl.js
import { useState, useCallback } from 'react';

const useRemoteControl = (isPeerConnected, sendSignal) => {
  const [isControlling, setIsControlling] = useState(false);

  const startRemoteControl = useCallback(() => {
    if (isPeerConnected) {
      setIsControlling(true);
      sendSignal({ type: 'startRemoteControl' });
    }
  }, [isPeerConnected, sendSignal]);

  const stopRemoteControl = useCallback(() => {
    setIsControlling(false);
    sendSignal({ type: 'stopRemoteControl' });
  }, [sendSignal]);

  const handleRemoteControlMessage = useCallback((message) => {
    // Handle remote control messages (e.g., mouse movements, clicks)
    console.log('Remote control message:', message);
    // Implement the logic to control the remote computer
  }, []);

  return {
    isControlling,
    startRemoteControl,
    stopRemoteControl,
    handleRemoteControlMessage,
  };
};

export default useRemoteControl;