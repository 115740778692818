// hooks/useRemoteControl.js
import { useState, useCallback, useEffect, useRef } from 'react';
import { socketEmit, socketOn, socketOff } from '../services/api';
import { showNotification } from '../utils/notification';
import InputManager from '../services/InputManager';
import CustomProtocol from '../services/CustomeProtocol';

const useRemoteControl = (roomId, userRole, peerRef) => {
  const [isControlling, setIsControlling] = useState(false);
  const [isRemoteControlRequested, setIsRemoteControlRequested] = useState(false);
  const [isRemoteControlActive, setIsRemoteControlActive] = useState(false);
  const [remoteControlStream, setRemoteControlStream] = useState(null);
  const remoteControlStreamRef = useRef(null);
  const inputManagerRef = useRef(null);
  const protocolRef = useRef(null);
  const hasJoinedRoom = useRef(false);

  useEffect(() => {
    inputManagerRef.current = new InputManager();
    protocolRef.current = new CustomProtocol();
    inputManagerRef.current.init();

    return () => {
      if (inputManagerRef.current) {
        inputManagerRef.current.cleanup();
      }
    };
  }, []);

  const requestRemoteControl = useCallback(() => {
    socketEmit('requestRemoteControl', { roomId });
    showNotification('Remote control requested', 'info');
  }, [roomId]);

  const startRemoteControlStream = useCallback(async () => {
    try {
      if (remoteControlStreamRef.current) {
        remoteControlStreamRef.current.getTracks().forEach(track => track.stop());
      }
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: 'always' },
        audio: false
      });
      remoteControlStreamRef.current = stream;
      setRemoteControlStream(stream);
      return stream;
    } catch (error) {
      console.error('Error starting remote control stream:', error);
      if (error.name === 'NotAllowedError') {
        showNotification('Screen sharing permission denied. Please allow screen sharing to use remote control.', 'error');
      } else {
        showNotification('Failed to start screen sharing. Please try again.', 'error');
      }
      throw error;
    }
  }, []);

  const approveRemoteControl = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      setRemoteControlStream(stream);
      socketEmit('approveRemoteControl', { roomId });
      setIsRemoteControlActive(true);
      setIsRemoteControlRequested(false);
    } catch (error) {
      console.error('Error starting screen share:', error);
      setIsRemoteControlRequested(false);
    }
  }, [roomId]);

  const denyRemoteControl = useCallback(() => {
    socketEmit('denyRemoteControl', { roomId });
    setIsRemoteControlRequested(false);
  }, [roomId]);


  const stopRemoteControl = useCallback(() => {
    if (remoteControlStream) {
      remoteControlStream.getTracks().forEach(track => track.stop());
      setRemoteControlStream(null);
    }
    socketEmit('stopRemoteControl', { roomId });
    setIsRemoteControlActive(false);
    setIsControlling(false);
  }, [roomId, remoteControlStream]);

  const handleRemoteControl = useCallback((event) => {
    if (isControlling && peerRef.current) {
      const rect = event.target.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      const data = {
        type: 'remoteControl',
        action: 'move',
        x,
        y,
      };
      peerRef.current.send(JSON.stringify(data));
    }
  }, [isControlling, peerRef]);

  const handleRemoteControlClick = useCallback((event) => {
    if (isControlling && peerRef.current) {
      const data = {
        type: 'remoteControl',
        action: 'click',
        button: event.button,
        clickType: event.type === 'mousedown' ? 'down' : 'up',
      };
      peerRef.current.send(JSON.stringify(data));
    }
  }, [isControlling, peerRef]);

  const handleRemoteControlScroll = useCallback((event) => {
    if (isControlling && peerRef.current) {
      const data = {
        type: 'remoteControl',
        action: 'scroll',
        deltaY: event.deltaY,
      };
      peerRef.current.send(JSON.stringify(data));
    }
  }, [isControlling, peerRef]);

  const sendRemoteControlInput = useCallback((inputData) => {
    if (peerRef.current && isControlling) {
      peerRef.current.send(JSON.stringify({ type: 'remoteControl', data: inputData }));
    }
  }, [isControlling]);

  useEffect(() => {
    socketOn('remoteControlRequested', () => {
      setIsRemoteControlRequested(true);
    });

    socketOn('remoteControlApproved', () => {
      setIsControlling(true);
      setIsRemoteControlActive(true);
    });

    socketOn('remoteControlDenied', () => {
      setIsRemoteControlRequested(false);
    });

    socketOn('remoteControlStopped', () => {
      setIsControlling(false);
      setIsRemoteControlActive(false);
    });

    return () => {
      socketOff('remoteControlRequested');
      socketOff('remoteControlApproved');
      socketOff('remoteControlDenied');
      socketOff('remoteControlStopped');
    };
  }, []);

  useEffect(() => {
    inputManagerRef.current = new InputManager();
    inputManagerRef.current.init();

    return () => {
      inputManagerRef.current.cleanup();
    };
  }, []);

  return {
    isControlling,
    setIsControlling,
    isRemoteControlRequested,
    setIsRemoteControlRequested,
    isRemoteControlActive,
    setIsRemoteControlActive,
    requestRemoteControl,
    approveRemoteControl,
    denyRemoteControl,
    stopRemoteControl,
    handleRemoteControl,
    handleRemoteControlClick,
    handleRemoteControlScroll,
    sendRemoteControlInput,
    remoteControlStream,
    setRemoteControlStream,
    remoteControlStreamRef,
    hasJoinedRoom,
  };
};

export default useRemoteControl;