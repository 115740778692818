// FrontEnd/src/services/CustomProtocol.js
export default class CustomProtocol {
    constructor() {
        this.encoder = new TextEncoder();
        this.decoder = new TextDecoder();
    }

    encodeInput(type, data) {
        const jsonString = JSON.stringify({ type, ...data });
        return this.encoder.encode(jsonString);
    }

    decodeInput(encodedData) {
        const jsonString = this.decoder.decode(encodedData);
        return JSON.parse(jsonString);
    }
}