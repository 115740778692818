import React from 'react';
import { Button, IconButton, Tooltip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,
  StopScreenShare,
  CallEnd,
  Phone,
  Mouse,
  Block
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  button: {
    margin: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  disabledIconButton: {
    color: theme.palette.action.disabled,
  },
  callButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  endCallButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const ControlPanel = ({
  isCallStarted,
  isMuted,
  isVideoOff,
  isScreenSharing,
  isControlling,
  isPeerConnected,
  toggleAudio,
  toggleVideo,
  startScreenSharing,
  stopScreenSharing,
  startRemoteControl,
  stopRemoteControl,
  startCall,
  endCall,
  currentUserRole,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Tooltip title={isMuted ? "Unmute" : "Mute"}>
          <IconButton
            className={classes.iconButton}
            onClick={toggleAudio}
            disabled={!isCallStarted}
          >
            {isMuted ? <MicOff /> : <Mic />}
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={isVideoOff ? "Turn on camera" : "Turn off camera"}>
          <IconButton
            className={classes.iconButton}
            onClick={toggleVideo}
            disabled={!isCallStarted}
          >
            {isVideoOff ? <VideocamOff /> : <Videocam />}
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={isScreenSharing ? "Stop sharing screen" : "Share screen"}>
          <IconButton
            className={classes.iconButton}
            onClick={isScreenSharing ? stopScreenSharing : startScreenSharing}
            disabled={!isCallStarted}
          >
            {isScreenSharing ? <StopScreenShare /> : <ScreenShare />}
          </IconButton>
        </Tooltip>
      </Grid>
      {currentUserRole === 'service_worker' && (
        <Grid item>
          <Tooltip title={isControlling ? "Stop remote control" : "Start remote control"}>
            <IconButton
              className={classes.iconButton}
              onClick={isControlling ? stopRemoteControl : startRemoteControl}
              disabled={!isPeerConnected}
            >
              {isControlling ? <Block /> : <Mouse />}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        {isCallStarted ? (
          <Button
            variant="contained"
            className={classes.endCallButton}
            startIcon={<CallEnd />}
            onClick={endCall}
          >
            End Call
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.callButton}
            startIcon={<Phone />}
            onClick={startCall}
          >
            Start Call
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ControlPanel;