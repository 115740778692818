// useWebRTC.js
import { useState, useEffect, useCallback, useRef } from 'react';
import Peer from 'simple-peer';
import { socketEmit } from '../services/api';
import { getIceServers } from '../utils/iceServers';

const useWebRTC = (roomId) => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isPeerConnected, setIsPeerConnected] = useState(false);

  const peerRef = useRef();

  const createPeer = useCallback((initiator) => {
    const peer = new Peer({
      initiator,
      trickle: false,
      config: { iceServers: getIceServers() },
    });

    peer.on('signal', (data) => {
      socketEmit('signal', { roomId, signal: data });
    });

    peer.on('stream', (stream) => {
      setRemoteStream(stream);
    });

    peer.on('connect', () => {
      setIsPeerConnected(true);
      setIsCallStarted(true);
    });

    peer.on('error', (err) => {
      console.error('Peer connection error:', err);
      // Handle error (e.g., show notification, retry connection)
    });

    peer.on('close', () => {
      setIsPeerConnected(false);
      setIsCallStarted(false);
    });

    return peer;
  }, [roomId]);

  const startCall = useCallback(() => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    peerRef.current = createPeer(true);
    if (localStream) {
      peerRef.current.addStream(localStream);
    }
  }, [createPeer, localStream]);

  const handleIncomingCall = useCallback((incomingSignal) => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    peerRef.current = createPeer(false);
    if (localStream) {
      peerRef.current.addStream(localStream);
    }
    peerRef.current.signal(incomingSignal);
  }, [createPeer, localStream]);

  const endCall = useCallback(() => {
    if (peerRef.current) {
      peerRef.current.destroy();
    }
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
    }
    setIsCallStarted(false);
    setIsPeerConnected(false);
    setRemoteStream(null);
  }, [localStream]);

  const toggleAudio = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsMuted(!audioTrack.enabled);
      }
    }
  }, [localStream]);

  const toggleVideo = useCallback(() => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsVideoOff(!videoTrack.enabled);
      }
    }
  }, [localStream]);

  const sendSignal = useCallback((signal) => {
    if (peerRef.current) {
      peerRef.current.signal(signal);
    }
  }, []);

  useEffect(() => {
    const setupMediaStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 },
            facingMode: 'user'
          },
          audio: true
        });
        setLocalStream(stream);
      } catch (err) {
        console.error('Error accessing media devices:', err);
        // Handle error (e.g., show notification, fallback to audio-only)
      }
    };

    setupMediaStream();

    return () => {
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return {
    localStream,
    remoteStream,
    isCallStarted,
    isMuted,
    isVideoOff,
    isPeerConnected,
    startCall,
    endCall,
    toggleAudio,
    toggleVideo,
    handleIncomingCall,
    sendSignal,
  };
};

export default useWebRTC;